// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Modules from '../components/modules'
// import RouteView from '../components/routeView'
//
// Vue.use(VueRouter)
// // name: 'index' 则不显示在tabs标签内
// const routes = [
//   {
//     path: '/',
//     redirect: to => {
//       return 'login'
//     },
//     hidden: true
//   },
//   {
//     path: '/login',
//     component: Modules.Login,
//     meta: {
//       name: '登陆'
//     }
//   }, {
//     path: '/file',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '文件管理',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'admissions'
//         },
//         hidden: true
//       },
//       {
//         path: 'admissions',
//         name: 'admissions',
//         meta: {
//           name: '资料下载'
//         },
//         component: Modules.File.admissions.Index
//       },
//       {
//         path: 'admissions/details/:packId',
//         name: 'details',
//         meta: {
//           name: '资料详情'
//         },
//         component: Modules.File.admissions.Details
//       }
//     ]
//   }
// ]
//
// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })
//
// export default router

import Vue from 'vue'
import VueRouter from 'vue-router'
import Modules from '../components/modules'
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace
// import RouteView from '../components/routeView'
Vue.use(VueRouter)
// name: 'index' 则不显示在tabs标签内
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => {}, () => {})
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => {
    }, () => {
    })
  }
}
const routes = [
  {
    path: '/',
    redirect: to => {
      return 'login'
    },
    hidden: true
  },
  {
    path: '/login',
    component: Modules.Login,
    meta: {
      name: '登陆'
    }
  },
  {
    path: '/two',
    component: Modules.TwoBall,
    meta: {
      name: '双色球'
    }
  }, {
    path: '*',
    component: Modules.notFindComponent
  }
]

// console.log(RouteView)
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
