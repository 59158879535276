import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css'
import { getCookie } from './util/tools/cookie'
import ElTreeSelect from 'el-tree-select'
import RouteView from '@/components/routeView'
import Modules from '@/components/modules'
// import Print from 'vue-print-nb'
import print from '@/util/print/print'

// 页面顶部进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


Vue.prototype.$echarts = require('echarts')
Vue.config.productionTip = false
function getSrtSubstring (path) {
  if (path.indexOf('/') > -1) {
    return path.substr(1)
  } else {
    return path
  }
}
function addReouterMatch (array) {
  return new Promise((resolve) => {
    let arr = []
    arr = array.map(item => {
      const listSplit = item.component.split('.')
      let module = ''
      if (item.component.indexOf('RouteView') > -1) {
        for (let i = 1; i < listSplit.length; i++) {
          module = module ? module[listSplit[i]] : RouteView[listSplit[i]]
        }
      } else if (item.component.indexOf('Modules') > -1) {
        for (let i = 1; i < listSplit.length; i++) {
          module = module ? module[listSplit[i]] : Modules[listSplit[i]]
        }
      }
      let listArr = []
      listArr = item.children.map(list => {
        let module = ''
        const splita = list.component.split('.')
        if (list.component.indexOf('RouteView') > -1) {
          for (let i = 1; i < splita.length; i++) {
            module = module ? module[splita[i]] : RouteView[splita[i]]
          }
        } else if (list.component.indexOf('Modules') > -1) {
          for (let i = 1; i < splita.length; i++) {
            module = module ? module[splita[i]] : Modules[splita[i]]
          }
        }
        return {
          path: getSrtSubstring(list.path),
          name: getSrtSubstring(list.path),
          meta: {
            name: list.name
          },
          component: module
        }
      })
      if (listArr.length > 0) {
        listArr = [{
          path: '/',
          redirect: to => {
            const str = listArr[0].path
            if (str.indexOf('/') > -1) {
              return str.substr(1)
            } else {
              return str
            }
          },
          hidden: true
        }, ...listArr]
      }
      return {
        path: item.path,
        meta: {
          name: item.name,
          keepAlive: true
        },
        component: module,
        children: listArr,
        name: item.path
      }
    })
    resolve(arr)
  })
}
function addTag (route) {
  if (route.path !== '/home/index') {
    store.dispatch('setRouteList', route)
    store.dispatch('addCachedView', route)
    console.log(store, route, 'this.$storethis.$store')
  }
}

// const router = new VueRouter({ router })
router.beforeEach((to, from, next) => {
  window.scroll(0, 0)
  NProgress.start()
  if (to.path === '/login') {
    next()
  } else if (to.path === '/two') {
    next()
  } else {
    if (store.state.login.token) {
      if (router.options.routes.length <= 4 && !store.state.login.isRouterSvae) {
        addReouterMatch(JSON.parse(store.state.login.rootMenu)).then(rt => {
          router.options.routes = router.options.routes.concat(rt)
          router.addRoutes(rt)
          // addTag(to)
          next(to.path)
        })
      } else {
        if (to.path.indexOf('/course/') > -1) {
          if (to.path === '/course/authority') {
            addTag(to)
            next()
          } else {
            if (store.state.login.otoStatus === 'true' || store.state.login.otoStatus === true) {
              addTag(to)
              next()
            } else {
              router.replace('/course/authority')
            }
          }
        } else {
          addTag(to)
          next()
        }
      }
    } else {
      router.replace('/login')
    }
  }
})
Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.use(ElTreeSelect)
Vue.use(print)

const i18n = new VueI18n({
  // 语言标识
  locale: getCookie('BELL_PLAY_LANG', 'zh'),
  // 通过切换locale的值来实现语言切换
  // this.$i18n.locale
  messages: {
    // 中文语言包
    zh: require('./util/i18n/zh.js'),
    // 英文语言包
    en: require('./util/i18n/en.js')
  }
})
router.afterEach(transition => {
  NProgress.done()
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
