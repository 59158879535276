import request from '@/config/request.js'
import setting from '@/config/settings'
const DoubleTeacher = request.DoubleTeacher;
export default {
  name: 'account-management',
  data() {
    return {
      lingjiaoCurrentUser: this.$store.state.login.lingjiaoCurrentUser ? JSON.parse(this.$store.state.login.lingjiaoCurrentUser) : null,
      accountData: null,
      classTypeValue: "",
      classStatus: 'NORMAL',
      searchValue: "",
      classTypesOption: [],
      classStatusOption: [{
        value: 'NORMAL',
        label: '正常'
      }, {
        value: 'DISABLE',
        label: '禁用'
      }],
      search_data: {},
      dialogVisible: false,
      modalClassPwd: false,
      classRoomId: null,
      modalClassItem: {},
      accountDetail: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
      classPwdRules: {
        terminalPwd: [
          { required: true, message: '请输入新的密码', trigger: 'blur' },
        ],
        terminalPwdAgain: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
        ],
      },
      paginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
    }
  },
  updated() {
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255;
    },
    loading: function () {
      return this.$store.state.global.ajax_loading;
    }
  },
  methods: {
    onSearch() {
    },
    expandVisible(row, rowIndex) {
      let data = row.row;
      let res = [];
      if (data.classRoomList && data.classRoomList.length > 0) {
        res.push('row-expand-has');
        return res
      } else {
        res.push('row-expand-cover');
        return res
      }
    },
    getClassTypes() {
      DoubleTeacher.findAllBymerchantId.call(this, { merchantId: this.lingjiaoCurrentUser.merchantId }, res => {
        if (res && res.success) {
          this.classTypesOption = res.data;
        }
      });
    },
    getAccountData() {
      const data = {
        status: this.classStatus,
        code: this.searchValue,
        pageNo: this.paginations.current_page,
        pageSize: this.paginations.page_size
      };
      if (this.classTypeValue === 'unbind') {
        DoubleTeacher.findTerminalListByCampusIdPage.call(this, data, res => {
          if (res && res.success) {
            this.accountData = res.data;
            this.paginations.total = res.data.totalSize;
          }
        });
      } else {
        data.classType = this.classTypeValue;
        DoubleTeacher.getAccountData.call(this, data, res => {
          if (res && res.success) {
            this.accountData = res.data;
            this.paginations.total = res.data.totalSize;
          }
        });
      }
    },
    formatText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
      }
      return text;
    },
    bindingTerminal(item, val) {
      DoubleTeacher.deleteBind.call(this, { classroomId: item.id, terminalUserId: val.id }, res => {
        if (res && res.success) {
          this.getAccountData();
        }
      });
    },
    bindingDefault(item, val) {
      DoubleTeacher.defaultClass.call(this, { classroomId: item.id, terminalUserId: val.id }, res => {
        if (res && res.success) {
          this.getAccountData();
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handleItemClick(val) {
      console.log("val:", val);
      this.accountDetail = { id: val.id, name: val.name };
      this.dialogVisible = true;
    },
    updateClassRoomName(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          DoubleTeacher.updateClassRoomName.call(this, this.accountDetail, res => {
            if (res && res.success) {
              this.getAccountData();
              this.dialogVisible = false;
            }
          });
        }
      });
    },
    updateClassPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const terminalPwd = this.modalClassItem.terminalPwd;
          const terminalPwdAgain = this.modalClassItem.terminalPwdAgain;
          if (this.classTypeValue !== 'unbind') {
            const reg = /^[0-9]{3,6}$/;
            if (!reg.test(terminalPwd)) {
              this.$message.error('密码只能设置为3至6位的数字');
              return;
            }
            if (terminalPwd !== terminalPwdAgain) {
              this.$message.error('两次输入的密码不一致');
              return;
            }
          }
          DoubleTeacher.updateTerminal.call(this, {
            id: this.classRoomId,
            password: terminalPwd,
          }, res => {
            if (res && res.success) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.modalClassPwd = false;
            } else {
              this.$message.error(res.message);
            }
            this.modalClassItem = {
              terminalPwd: '',
              terminalPwdAgain: ''
            };
          });
        }
      });
    },
    terminalPwd(item) {
      this.classRoomId = item.id;
      this.modalClassPwd = true;
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage(page) {
      console.log("page:", page);
      this.paginations.current_page = page;
      this.getAccountData();
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize(pageSize) {
      console.log("pageSize:", pageSize);
      this.paginations.page_size = pageSize;
      this.getAccountData();
    },
  },
  mounted() {
    this.getClassTypes();
    this.getAccountData();
  }
}
