var configEnv = 'dev'
const settings = {
  version: configEnv,
  cbs: {
    statusError (err) {
      if (err.code) {
        this.$message({
          showClose: true,
          message: '返回错误：' + err.msg,
          type: 'error'
        })
      } else {
        this.$message({
          showClose: true,
          message: '返回错误：' + err.msg,
          type: 'error'
        })
      }
    },
    /**
     * * ajax请求网络出错时调用
     * */
    requestError (err) {
      if (err.resp_code) {
        this.$message({
          showClose: true,
          message: '请求错误：' + err.resp_code + ',' + err.msg,
          type: 'error'
        })
      }
    }
  }
}
// 三套基本配置
if (configEnv === 'dev') {
  settings.gbs = {
    host: 'https://angelbell.anqinyun.com/',
    // host: 'http://192.168.10.161:9202/',
    db_prefix: 'bell_admin_',
    qiniu: 'https://qiniu.anqinyun.com/',
    folderPath: 'https://angelbell.anqinyun.com/fmp',
    zipPath: 'https://angelbell.anqinyun.com/fmd',
    code: 'https://angelbell.anqinyun.com?',
    idCard: 'https://faceid.tencentcloudapi.com/',
    fileDownLoad: 'http://angelbell.anqinyun.com/fmd1/',
    doubleTeacher: 'https://cms.anqinyun.com/api'
    // doubleTeacher: 'http://192.168.10.161:8500'
  }
} else if (configEnv === 'test') {
  settings.gbs = {
    host: ''
  }
} else if (configEnv === 'prev') {
  settings.gbs = {
    host: ''
  }
} else {
  settings.gbs = {
    host: ''
  }
}
export default settings
