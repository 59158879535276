// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
// // 设置语言
// locale.use(lang)
import Vue from 'vue'
import request from '@/config/request.js'
// import tools from '@/util/tools'
import { Message } from 'element-ui'
import setting from '@/config/settings'
import RouteView from "@/components/routeView";
import Modules from "@/components/modules";
// const validate = tools.validate
const ApiUser = request.UserLogin
const ApiLogin = request.UserLogin
const DoubleTeacher = request.DoubleTeacher
export default {
  name: 'header-nav',
  data () {
    // const validPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error(' '))
    //     return false
    //   } else if (!validate.isvalidPhone(value)) {
    //     callback(new Error(' '))
    //     return false
    //   } else {
    //     callback()
    //   }
    // }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.passInfo.confirmPassword !== '') {
          this.$refs.headerPassForm.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      language: 'zh',
      lang: [{
        // 模板语法的一种
        label: this.$t('message.zh'),
        value: 'zh'
      }, {
        label: this.$t('message.en'),
        value: 'en'
      }],
      qiniu: setting.gbs.qiniu,
      isCollapse: false,
      value: '',
      userInfo: JSON.parse(this.$store.state.login.userInfo),
      dropIcon: 'el-icon-arrow-down',
      userDialog: false,
      passWordDialog: false,
      formLabelWidth: '120px',
      passRules: {
        password: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      passInfo: {
        mobile: JSON.parse(this.$store.state.login.userInfo).mobile,
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      feedbackDialog: false,
      feedbackForm: {
        feedback: ''
      },
      feedbackRules: {
        feedback: [
          { required: true, message: '请输入您需要提的意见或者建议', trigger: 'blur' }
        ]
      },
      changeTeacher: false,
      changeItem: '',
      changeTeacherList: JSON.parse(this.$store.state.login.schoolsTeacher)
    }
  },
  computed: {
    route () {
      return this.$route
    }
  },
  watch: {
    // 侦听器
    language: function (val) {
      // 侦听单选按钮的变化，从而进行切换语言
      val === 'zh' ? this.$i18n.locale = 'zh' : this.$i18n.locale = 'en'
      Vue.set(this.lang, 'zh', { label: this.$t('message.zh'), value: 'zh' })
      Vue.set(this.lang, 'en', { label: this.$t('message.en'), value: 'en' })

      var Days = 30
      var exp = new Date()
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
      document.cookie = 'BELL_PLAY_LANG' + '=' + escape(val) + ';expires=' + exp.toGMTString()
    }
  },
  mounted () {
    this.$store.dispatch('setRouteList', this.$route)
  },
  methods: {
    getSrtSubstring (path) {
      if (path.indexOf('/') > -1) {
        return path.substr(1)
      } else {
        return path
      }
    },
    routeMatch (array) {
      return new Promise((resolve) => {
        let arr = []
        arr = array.map(item => {
          const listSplit = item.component.split('.')
          let module = ''
          if (item.component.indexOf('RouteView') > -1) {
            for (let i = 1; i < listSplit.length; i++) {
              module = module ? module[listSplit[i]] : RouteView[listSplit[i]]
            }
          } else if (item.component.indexOf('Modules') > -1) {
            for (let i = 1; i < listSplit.length; i++) {
              module = module ? module[listSplit[i]] : Modules[listSplit[i]]
            }
          }
          let listArr = []
          listArr = item.children.map(list => {
            let module = ''
            const splita = list.component.split('.')
            if (list.component.indexOf('RouteView') > -1) {
              for (let i = 1; i < splita.length; i++) {
                module = module ? module[splita[i]] : RouteView[splita[i]]
              }
            } else if (list.component.indexOf('Modules') > -1) {
              for (let i = 1; i < splita.length; i++) {
                module = module ? module[splita[i]] : Modules[splita[i]]
              }
            }
            return {
              path: this.getSrtSubstring(list.path),
              name: this.getSrtSubstring(list.path),
              meta: {
                name: list.name
              },
              component: module
            }
          })
          if (listArr.length > 0) {
            listArr = [{
              path: '/',
              redirect: to => {
                const str = listArr[0].path
                if (str.indexOf('/') > -1) {
                  return str.substr(1)
                } else {
                  return str
                }
              },
              hidden: true
            }, ...listArr]
          }
          return {
            path: item.path,
            meta: {
              name: item.name,
              keepAlive: true
            },
            component: module,
            children: listArr,
            name: item.path
          }
        })
        resolve(arr)
      })
    },
    setMenu (menu) {
      return new Promise((resolve) => {
        this.$store.dispatch('setRootMenu', JSON.stringify(menu))
        resolve()
      })
    },
    getTeacher () {
      ApiLogin.changeLoginZoneTeacher2.call(this, { zoneTeacherId: this.teacherID }, (res) => {
        this.$store.dispatch('loginIn', 'Bearer ' + res.token)
        this.$store.dispatch('setZoneId', res.zoneTeacher.schoolZone.zoneId)
        this.$nextTick(() => {
          ApiLogin.zoneFeatureIsBroadcast.call(this, {}, item => {
            this.$store.dispatch('setOtoStatus', item)
          })
          ApiLogin.getTeacherAccountInfoByTeacherId.call(this, { teacherId: this.teacherID }, item => {
            this.$store.dispatch('setUserInfo', JSON.stringify(item))
            this.userInfo = item
            ApiLogin.getMenus.call(this, {}, dataMenu => {
              if (dataMenu.length === 0) {
                Message({
                  message: '未分配任何菜单权限，请联系管理员！',
                  type: 'error'
                })
              }
              this.changeTeacher = false
              this.setMenu(dataMenu).then(() => {
                this.routeMatch(JSON.parse(this.$store.state.login.rootMenu)).then(rt => {
                  this.$router.options.routes = this.$router.options.routes.concat(rt)
                  this.$router.addRoutes(rt)
                  for (const router in dataMenu) {
                    if (dataMenu[router].children.length > 0) {
                      this.$nextTick(() => {
                        const menuPath = dataMenu[router].children[0].path
                        const path = menuPath
                        this.$router.push({
                          path: dataMenu[router].path + path
                        })
                      })
                      break
                    } else {
                      this.$nextTick(() => {
                        this.$router.push({
                          path: dataMenu[router].path
                        })
                      })
                    }
                  }
                })
              })
            })
          })
        })
      })
    },
    changeRadio (e) {
      this.teacherID = e
    },
    feedbackSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.feedbackSave.call(this, { content: that.feedbackForm.feedback }, data => {
            that.$message({
              message: data.result,
              type: 'success'
            })
            this.feedbackDialog = false
          })
        }
      })
    },
    onDropDown (e) {
      if (e) {
        this.$data.dropIcon = 'el-icon-arrow-up'
      } else {
        this.$data.dropIcon = 'el-icon-arrow-down'
      }
    },
    changeMenu (e) {
      this.isCollapse = e
      this.$store.dispatch('changeIsCollapse', e)
    },
    onUpdatePassWord (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.changPassword.call(this, that.passInfo, data => {
            that.$message({
              message: '修改成功',
              type: 'success'
            })

            this.passWordDialog = false
          })
        }
      })
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.updateSelfInfo.call(this, that.info, data => {
            ApiUser.getUserInfo.call(this, {}, userinfo => {
              that.$store.dispatch('setUserInfo', JSON.stringify(userinfo.data))
              that.$message({
                message: '保存成功',
                type: 'success'
              })
              this.userDialog = false
            })
          })
        }
      })
    },
    getFeedback () {
      this.feedbackDialog = true
    },
    selectCommand (val) {
      if (val === 'info') {
        this.info = {
          trueName: this.userInfo.trueName,
          phone: this.userInfo.phone
        }
        this.userDialog = true
      }
      if (val === 'password') {
        this.passWordDialog = true
      }
      if (val === 'loginout') {
        this.$confirm('确定退出系统吗？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('delUserLogin')
          this.$store.dispatch('clearRoute', [])
          this.$router.push(
            {
              path: '/login'
            }
          )
          DoubleTeacher.logout.call(this, {}, res => {});
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          })
        })
      }
      if (val === 'feedback') {
        this.feedbackDialog = true
      }
      if (val === 'changeTeacher') {
        this.changeTeacher = true
      }
    }
  }
}
