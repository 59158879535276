import request from '@/config/request.js';
import setting from '@/config/settings';
import moment from 'moment';
const DoubleTeacher = request.DoubleTeacher;
export default {
    name: 'account-permission',
    data() {
        return {
            packageAuthData: null,
            searchValue: "",
            search_data: {},
            mdseTermVisible: false,
            mdseTermTitle: '',
            mdseTermInfo: {
                classRoomName: '',
                classType: '',
                name: '',
                status: ''
            },
            mdseProdList: [],
            mdseProdsTitle: '',
            mdseProdsVisible: false,
            termList: [],
            paginations: {
                current_page: 1,
                total: 0,
                page_size: 10,
                page_sizes: [10, 20, 30],
                layout: 'total, sizes, prev, pager, next, jumper'
            },
        }
    },
    updated() {
    },
    computed: {
        tableHight: function () {
            return window.innerHeight - 255;
        },
        loading: function () {
            return this.$store.state.global.ajax_loading;
        }
    },
    methods: {
        getAllTermList() {
            DoubleTeacher.getAllTermList.call(this, {}, res => {
                if (res && res.success) {
                    this.termList = res.data;
                }
            });
        },
        getPackageAuths() {
            DoubleTeacher.packageAuthsPage.call(this, { ucode: this.searchValue, pageNo: this.paginations.current_page, pageSize: this.paginations.page_size }, res => {
                if (res && res.success) {
                    this.packageAuthData = res.data;
                    this.paginations.total = res.data.totalSize;
                }
            });
        },
        /**
         * 改变当前页事件
         * @param  {number} page 当前页码
         */
        onChangeCurrentPage(page) {
            this.paginations.current_page = page;
            this.getPackageAuths();
        },
        /**
         * 改变每页显示数量事件
         * @param  {number} size 当前每页显示数量
         */
        onChangePageSize(pageSize) {
            this.paginations.page_size = pageSize;
            this.getPackageAuths();
        },
        showModalMdseTerminal(ucode) {
            DoubleTeacher.getUserDetails.call(this, ucode, res => {
                if (res && res.success) {
                    this.mdseTermInfo = res.data;
                    this.mdseTermTitle = ucode;
                    this.mdseTermVisible = true;
                }
            });
        },
        showModalMdseProds(pkgId, name) {
            DoubleTeacher.getPackageProductDetails.call(this, pkgId, res => {
                if (res && res.success) {
                    this.mdseProdList = res.data.mps;
                    this.mdseProdsTitle = name;
                    this.mdseProdsVisible = true;
                }
            });
        },
        timeRemaining(val) {
            const timestamp = new Date().getTime();
            const endTime = val.endTime;
            return Math.floor((endTime - timestamp) / (1000 * 60 * 60 * 24));
        },
        dateFormat(date, format) {
            return moment(date).format(format);
        },
        handleClose(done) {
            done();
        },
        getClassTypeByValue(val) {
            const enumMap = {
                1: '托班',
                2: '小班',
                3: '中班',
                4: '大班',
                5: '学前班',
            };
            return enumMap[val] || '通用';
        },
        getStatusName(status) {
            const enumMap = {
                NORMAL: '正常',
                DEL: '已删除',
                DISABLE: '已禁用'
            };
            return enumMap[status] || '未知';
        },
        getTermName(term) {
            const array = this.termList.filter(item => item.term === term);
            if (array.length > 0) {
                return array[0].name;
            }
            return '-';
        }
    },
    mounted() {
        this.getAllTermList();
        this.getPackageAuths();
    }
}
