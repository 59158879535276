import request from '@/config/request.js';
import setting from '@/config/settings';
import moment from 'moment';
const DoubleTeacher = request.DoubleTeacher;
export default {
    name: 'time-table-period',
    data() {
        return {
            lingjiaoCurrentUser: this.$store.state.login.lingjiaoCurrentUser ? JSON.parse(this.$store.state.login.lingjiaoCurrentUser) : null,
            currentItem: null,
            timetableClassList: [],
            totalLesson: 28
        }
    },
    updated() {
    },
    computed: {
        tableHight: function () {
            return window.innerHeight - 255;
        },
        loading: function () {
            return this.$store.state.global.ajax_loading;
        }
    },
    methods: {
        getTimetableClassList() {
            DoubleTeacher.getTimetableClassList.call(this, {
                classRoomId: this.currentItem.classRoomId,
                termYear: this.currentItem.termYear,
                classType: this.currentItem.classType,
                term: this.currentItem.term
            }, res => {
                if (res && res.success) {
                    this.timetableClassList = res.data;xxxxxxx
                    console.log("this.timetableClassList:", this.timetableClassList);
                }
            });
        },
        handleWeekDetail(item) {
            const params = {};
            params.classType = this.currentItem.classType;
            params.term = this.currentItem.term;
            params.termWeek = item.termWeek;
            params.termYear = item.termYear;
            params.id = item.id;
            params.classRoomId = item.classRoomId;

            this.$store.commit('setTimetableWeek', params);  
            this.$router.push({ name: 'classManagement/timeTableWeek' });

        },
        getTimetableLessonNum() {
            // const { educationStage } = this.lingjiaoCurrentUser;
            this.totalLesson = 56;
        },
        formatDateRange(val) {
            let date = '-';
            if (this.isNotNull(val.startDate) && this.isNotNull(val.endDate)) {
                date = moment(val.startDate).format('YYYY-MM-DD') + ' 至 ' + moment(val.endDate).format('YYYY-MM-DD');
            } else if (!this.isNotNull(val.startDate) && this.isNotNull(val.endDate)) {
                date = '- 至 ' + moment(val.endDate).format('YYYY-MM-DD');
            } else if (this.isNotNull(val.startDate) && !this.isNotNull(val.endDate)) {
                date = moment(val.startDate).format('YYYY-MM-DD') + ' 至 -';
            }

            return date;
        },
        isNotNull(val) {
            return val !== null && val !== undefined;
        },
        isNull(val) {
            return val === null || val === undefined;
        }
    },
    created() {
        // this.currentItem = this.$route.query.currentItem;
        this.currentItem = this.$store.state.timeTablePeriod;
        console.log("currentItem:", this.currentItem);
    },
    mounted() {
        this.getTimetableLessonNum();
        this.getTimetableClassList();
    }
}
