import request from '@/config/request.js'
import setting from "@/config/settings";
const ApicourseManagement = request.Course.courseManagement
export default {
  name: 'course-management',
  data () {
    return {
      myHeaders: { Authorization: this.$store.state.login.token },
      // 总部树节点
      rootFolders: [],
      // 校区树节点
      schoolsRootFolders: [],
      // 树节点的配置
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 选择的类别
      selectName: 'root',
      // 总部课节分页组件信息
      LessonPaginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      // 总部课件分页组件信息
      coursePaginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      // 校区课节分页组件信息
      SchoolsLessonPaginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      // 校区课件分页组件信息
      SchoolscoursePaginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      formLabelWidth: '140px',
      selectCourse: 'kejie',
      // 总部
      // 课节
      LessonTable: [],
      lessonId: '',
      // 课件
      CoursewareTable: [],
      coursewareId: '',
      // 校区
      // 课节
      SchoolsLessonTable: [],
      schoolsLessonId: '',
      // 课件
      SchoolsCoursewareTable: [],
      schoolsCoursewareId: '',
      // 详细描述
      CourseDetails: {},
      SchoolsCourseDetails: {},
      // 课节弹窗控制
      lessonDialog: false,
      // 课节form验证
      lessonRules: {
        sectionName: [
          { required: true, message: '请输入课节名称', trigger: 'blur' }
        ],
        teachingDuration: [
          { required: true, message: '请输入课节名称', trigger: 'blur' }
        ]
      },
      // 课节字段
      lessonDetails: {},
      lessonType: '',
      // 教学环节
      segmentDetailsType: 'add',
      segmentDetails: {},
      segmentDialog: false,
      // 教学环节验证
      segmentDetailsRules: {
        segmentName: [
          { required: true, message: '请输入课程环节名称', trigger: 'blur' }
        ],
        segmentContent: [
          { required: true, message: '请输入环节描述', trigger: 'blur' }
        ]
      },
      // 校区课件 弹窗控制
      courseWareDialog: false,
      // 校区课件详细
      courseWareDetails: {
        courseSection: {

        }
      },
      courseWareDetailsRules: {

      },
      courseWareType: '',
      // 资源类型
      fileType: [
        {
          title: '视频',
          value: 'VIDEO'
        }, {
          title: '音频',
          value: 'AUDIO'
        }, {
          title: 'PDF',
          value: 'PDF'
        }, {
          title: 'PPT',
          value: 'PPT'
        }, {
          title: 'Word',
          value: 'WORD'
        }, {
          title: '图片',
          value: 'PIC'
        }
      ],
      courseList: [],
      coursewareUrl: '',
      coursewareTypeFilters: [
        {
          key: 'VIDEO',
          filters: ['mp4', 'avi', 'mov'],
          size: 50
        }, {
          key: 'AUDIO',
          filters: ['mp3'],
          size: 50
        }, {
          key: 'PDF',
          filters: ['pdf'],
          size: 20
        }, {
          key: 'PPT',
          filters: ['ppt', 'pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint'],
          size: 20
        }, {
          key: 'WORD',
          filters: ['doc', 'docx', 'application/msword'],
          size: 20
        }, {
          key: 'PIC',
          filters: ['png', 'jpg', 'jpeg'],
          size: 20
        }
      ],
      fileList: [],
      // 新建课程分裂
      newCourseWareTypeDialog: false,
      courseTypeDetail: {
        enabled: false
      },
      parentCategory: [],
      // 课节form验证
      courseTypeRules: {
        categoryName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        parentCategoryId: [
          { required: true, message: '请选择上级分类', trigger: 'change' }
        ]
      },
      categoryId: '',
      styles: {
        width: '300px'
      },
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: '请输入内容'
      },
      treeParams: {
        clickParent: true,
        filterable: true,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'title',
          value: 'key'
        }
      },
      node: '',
      resolve: '',
      nodeData: {},
      // 编辑课程分类
      editCourseWareTypeDialog: false,
      editCourseTypeDetail: {
        categoryId: '',
        categoryName: '',
        courseCategory: {},
        enabled: false,
        layerCode: '',
        parentCategoryId: ''
      },
      // 新建课程
      newCourseWareDialog: false,
      newCourseWareDetail: {},
      courseRules: {
        courseName: [
          { required: true, message: '请输入课程名称', trigger: 'blur' }
        ],
        grade: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        subject: [
          { required: true, message: '请选择学科', trigger: 'change' }
        ],
        courseContent: [
          { required: true, message: '请输入课程描述', trigger: 'blur' }
        ]
      },
      // 'FIRST_GRADE', 'SECOND_GRADE', 'THIRD_GRADE', 'FOURTH_GRADE', 'FIFTH_GRADE', 'SIXTH_GRADE', 'YOUNG_CONNECTION', 'OTHER'
      grades: [
        {
          name: '一年级',
          value: 'FIRST_GRADE'
        },
        {
          name: '二年级',
          value: 'SECOND_GRADE'
        },
        {
          name: '三年级',
          value: 'THIRD_GRADE'
        },
        {
          name: '四年级',
          value: 'FOURTH_GRADE'
        },
        {
          name: '五年级',
          value: 'FIFTH_GRADE'
        },
        {
          name: '六年级',
          value: 'SIXTH_GRADE'
        },
        {
          name: '幼小',
          value: 'YOUNG_CONNECTION'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      // 'CHINESE', 'MATHEMATICS', 'ENGLISH', 'SCIENCE', 'ART'
      subjects: [
        {
          name: '语文',
          value: 'CHINESE'
        }, {
          name: '数学',
          value: 'MATHEMATICS'
        }, {
          name: '英语',
          value: 'ENGLISH'
        }, {
          name: '科学',
          value: 'SCIENCE'
        }, {
          name: '美术',
          value: 'ART'
        }
      ],
      editCourseWareDialog: false,
      editCourseWareDetail: {
        courseId: '',
        courseContent: '',
        courseName: '',
        enabled: '',
        layerCode: '',
        courseCategoryId: '',
        remark: '',
        subject: '',
        textbookVersion: '',
        grade: ''
      },
      urlHost: setting.gbs.host,
    }
  },
  computed: {
    tableHight: function () {
      if (JSON.stringify(this.CourseDetails) === '{}') {
        return window.innerHeight - 245
      } else {
        return window.innerHeight - 420
      }
    },
    schoolsTableHight: function () {
      if (JSON.stringify(this.SchoolsCourseDetails) === '{}') {
        return window.innerHeight - 245
      } else {
        return window.innerHeight - 455
      }
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    beforeHandleCommand (data, command, node) {
      return {
        data: data,
        command: command,
        node: node
      }
    },
    remove (node, data) {
      const parent = node.parent
      const children = parent.childNodes || parent.data
      const dataChildren = parent.data.children
      const index = children.findIndex(d => d.data.key === data.key)
      children.splice(index, 1)
      if (dataChildren) {
        const dataIndex = dataChildren.findIndex(d => d.key === data.key)
        dataChildren.splice(dataIndex, 1)
      }
    },
    removeCourseCategoryById (node, data) {
      if (data.isLeaf) {
        this.$confirm('是否确定删除该节点？删除后不可恢复。是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ApicourseManagement.removeCourseById.call(this, { courseId: node.data.layerCode }, () => {
            this.remove(node, data)
          })
        }).catch(() => {
        })
      } else {
        this.$confirm('是否确定删除该节点？删除后不可恢复。是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ApicourseManagement.removeCourseCategoryById.call(this, { categoryId: node.data.key }, () => {
            this.remove(node, data)
          })
        }).catch(() => {
        })
      }
    },
    // 下拉框修改
    _selectChange (val) {
    },
    // 树点击
    _nodeClickFun (data, node, vm) {
    },
    // 树过滤
    _searchFun (value) {
      // 自行判断 是走后台查询，还是前端过滤
      this.$refs.treeSelect.filterFun(value)
    },
    // 自定义render
    _renderFun (h, { node, data, store }) {
      return (
        <span class='custom-tree-node'>
          <span>{node.label}</span>
        </span>
      )
    },
    getParentCategory (node) {
      this.node = node
    },
    addCateGory (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.courseTypeDetail = Object.assign(this.courseTypeDetail, {
            courseCategory: {
              categoryId: this.courseTypeDetail.parentCategoryId
            }
          })
          // if (this.courseTypeDetail.parentCategoryId)
          ApicourseManagement.addCourseCategory.call(this, this.courseTypeDetail, (item) => {
            this.newCourseWareTypeDialog = false
            this.courseTypeDetail = {}
            const data = this.nodeData
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            data.children.push({
              checked: item.checked,
              disabled: item.disabled,
              expanded: item.expanded,
              folder: item.folder,
              icon: item.icon,
              isLeaf: item.isLeaf,
              key: item.key,
              layerCode: item.layerCode,
              lazy: item.lazy,
              parentKey: item.parentKey,
              selected: item.selected,
              title: item.title,
              type: item.type,
              unselectable: item.unselectable
            })
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    editCateGory (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApicourseManagement.updateCourseCategory.call(this, this.editCourseTypeDetail, (item) => {
            this.editCourseWareTypeDialog = false
            this.editCourseTypeDetail = {
              categoryId: '',
              categoryName: '',
              courseCategory: {},
              enabled: false,
              layerCode: '',
              parentCategoryId: ''
            }
            that.node.data = {
              checked: item.checked,
              disabled: item.disabled,
              expanded: item.expanded,
              folder: item.folder,
              icon: item.icon,
              isLeaf: item.isLeaf,
              key: item.key,
              layerCode: item.layerCode,
              lazy: item.lazy,
              parentKey: item.parentKey,
              selected: item.selected,
              title: item.title,
              type: item.type,
              unselectable: item.unselectable
            }
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    onShowTypeEdit  (node, data) {
      this.nodeData = data
      this.node = node
      let id = ''
      if (data.isLeaf) {
        id = data.layerCode
      } else {
        id = data.key
      }
      if (!data.isLeaf) {
        ApicourseManagement.getCourseCategoryById.call(this, { categoryId: id }, (data) => {
          this.editCourseTypeDetail = {
            categoryId: data.categoryId,
            categoryName: data.categoryName,
            courseCategory: data.courseCategory,
            enabled: data.enabled,
            layerCode: data.layerCode,
            parentCategoryId: data.courseCategory ? data.courseCategory.categoryName : '校区资源',
            remark: data.remark
          }
          this.editCourseWareTypeDialog = true
        })
      } else {
        ApicourseManagement.getCourseById.call(this, { courseId: id }, (data) => {
          const grades = this.grades.filter(item => {
            if (item.name === data.grade) {
              return item
            }
          })
          this.$nextTick(() => {
            this.editCourseWareDetail = {
              courseId: parseInt(id),
              courseContent: data.courseContent,
              courseName: data.courseName,
              enabled: data.enabled,
              layerCode: data.layerCode,
              courseCategoryId: data.courseCategory ? data.courseCategory.categoryName : '校区资源',
              remark: data.remark,
              subject: data.subject,
              textbookVersion: data.textbookVersion,
              grade: grades[0] ? grades[0].value : '',
              courseCategory: { categoryId: data.courseCategory.categoryId }
            }
            this.editCourseWareDialog = true
          })
        })
      }
    },
    onShowType ({ data, command, node }) {
      this.node = node
      if (command === 'type') {
        this.nodeData = data
        if (data.isLeaf) {
          this.courseTypeDetail.parentCategoryId = data.layerCode
        } else {
          this.courseTypeDetail.parentCategoryId = data.key
        }

        ApicourseManagement.getZoneCourseCategoryTree.call(this, {}, (data) => {
          // this.parentCategory = data
          // this.treeParams.data = data
          this.newCourseWareTypeDialog = true
        })
      } else {
        this.nodeData = data
        if (data.isLeaf) {
          this.newCourseWareDetail.courseCategoryId = data.layerCode
        } else {
          this.newCourseWareDetail.courseCategoryId = data.key
        }
        ApicourseManagement.getZoneCourseCategoryTree.call(this, {}, (data) => {
          this.newCourseWareDialog = true
        })
      }
    },
    addCourse (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.newCourseWareDetail = Object.assign(this.newCourseWareDetail, {
            courseCategory: {
              categoryId: this.newCourseWareDetail.courseCategoryId
            }
          })
          ApicourseManagement.addCourse.call(this, this.newCourseWareDetail, (item) => {
            this.newCourseWareDialog = false
            this.newCourseWareDetail = {}
            const data = this.nodeData
            if (!data.children) {
              this.$set(data, 'children', [])
            }
            data.children.push({
              checked: item.checked,
              disabled: item.disabled,
              expanded: item.expanded,
              folder: item.folder,
              icon: item.icon,
              isLeaf: item.isLeaf,
              key: item.key,
              layerCode: item.layerCode,
              lazy: item.lazy,
              parentKey: item.parentKey,
              selected: item.selected,
              title: item.title,
              type: item.type,
              unselectable: item.unselectable
            })
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    editCourse (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApicourseManagement.updateCourse.call(this, this.editCourseWareDetail, (item) => {
            this.editCourseWareDialog = false
            this.editCourseWareDetail = {
              courseId: '',
              courseContent: '',
              courseName: '',
              enabled: '',
              layerCode: '',
              courseCategoryId: '',
              remark: '',
              subject: '',
              textbookVersion: '',
              grade: ''
            }
            that.node.data = {
              checked: item.checked,
              disabled: item.disabled,
              expanded: item.expanded,
              folder: item.folder,
              icon: item.icon,
              isLeaf: item.isLeaf,
              key: item.key,
              layerCode: item.layerCode,
              lazy: item.lazy,
              parentKey: item.parentKey,
              selected: item.selected,
              title: item.title,
              type: item.type,
              unselectable: item.unselectable
            }
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    handleAvatarSuccess (res, file) {
      this.courseWareDetails.coursewareName = file.name
    },
    beforeAvatarUpload (file) {
      const isType = file.type
      const isSize = file.size / 1024 / 1024
      if (!this.courseWareDetails.coursewareType) {
        this.$message.error('请选择上传文件格式!')
        return false
      }
      const results = this.coursewareTypeFilters.filter(f => f.key === this.courseWareDetails.coursewareType)
      let type = false
      results[0].filters.filter((r) => {
        if (isType.indexOf(r) >= 0) {
          type = true
        }
      })
      if (type) {
        if (isSize > results[0].size) {
          this.$message.error('上传文件超出预定大小!')
          return false
        } else {
          return true
        }
      } else {
        this.$message.error('上传文件格式不合法!')
        return false
      }
    },
    addAndSaveCourse (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const type = this.courseWareType === 'add' ? 'addCourseware' : 'updateCourseware'
          if (this.courseWareType === 'add') {
            this.courseList.filter((item) => {
              if (this.courseWareDetails.sectionId === item.sectionId) {
                this.courseWareDetails.courseSection = item
              }
            })
            delete this.courseWareDetails.sectionId
          }
          ApicourseManagement[type].call(this, this.courseWareDetails, () => {
            that.courseWareDialog = false
            that.$nextTick(() => {
              this.courseWareDetails = {
                courseSection: {

                }
              }
              this.fileList = []
              this.getSchoolsCourseware()
            })
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    // 获取文件名
    getStringFileName (path) {
      if (path) {
        var pos1 = path.lastIndexOf('/')
        var pos2 = path.lastIndexOf('\\')
        var pos = Math.max(pos1, pos2)
        if (pos < 0) {
          return path
        } else {
          return path.substring(pos + 1)
        }
      }
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除${file.name}？`)
    },
    getRowClass (row, index) {
      // 即改行没有子元素时，添加row-expand-cover类
      if (!row.row.courseSegments) {
        return 'row-expand-cover'
      }
    },
    removeCourseSegmentById (row) {
      const that = this
      this.$confirm('是否确定删除该教学环节？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.removeCourseSegmentById.call(this, { segmentId: row.segmentId }, () => {
          that.getSchoolsLesson()
        })
      }).catch(() => {
      })
    },
    addCourseSegment (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const type = this.segmentDetailsType === 'add' ? 'addCourseSegment' : 'updateCourseSegment'
          ApicourseManagement[type].call(this, this.segmentDetails, () => {
            this.segmentDialog = false
            this.segmentDetails = {}
            this.getSchoolsLesson()
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    editLink (row) {
      this.segmentDetailsType = 'edit'
      this.segmentDetails = Object.assign({}, {
        segmentId: row.segmentId,
        segmentName: row.segmentName,
        segmentContent: row.segmentContent
      })
      this.segmentDialog = true
    },
    addLink (row) {
      this.segmentDetailsType = 'add'
      this.segmentDetails = Object.assign({}, {
        courseSection: {
          sectionId: row.sectionId
        },
        segmentName: '',
        segmentContent: ''
      })
      this.segmentDialog = true
    },
    enableCourseSection (row) {
      const that = this
      this.$confirm('确定' + (row.enabled ? '禁用' : '启用') + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.enableCourseSection.call(this, { sectionId: row.sectionId, enabled: !row.enabled, ajaxType: 'form' }, () => {
          that.getSchoolsLesson()
        })
      }).catch(() => {
      })
    },
    enableCourseWareSection (row) {
      const that = this
      this.$confirm('确定' + (row.enabled ? '禁用' : '启用') + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.enableCourseware.call(this, { wareId: row.wareId, enabled: !row.enabled, ajaxType: 'form' }, () => {
          that.getSchoolsCourseware()
        })
      }).catch(() => {
      })
    },
    removeCoursewareById (row) {
      const that = this
      this.$confirm('是否确定删除该课件？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.removeCoursewareById.call(this, { wareId: row.wareId }, () => {
          that.getSchoolsCourseware()
        })
      }).catch(() => {
      })
    },
    queryDocumentTranscodeTaskResult (row) {
      const that = this
      this.$confirm('确认手动查询转码任务结果吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.queryDocumentTranscodeTaskResult.call(this, { wareId: row.wareId, ajaxType: 'form' }, () => {
          this.$message({
            message: '手动查询转码结果成功',
            type: 'success'
          })
          that.getSchoolsCourseware()
        })
      }).catch(() => {
      })
    },
    submitDocumentTranscodeTask (row) {
      const that = this
      this.$confirm('确认提交转码请求吗？提交文档到腾讯云转码为收费服务，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.submitDocumentTranscodeTask.call(this, { wareId: row.wareId, ajaxType: 'form' }, () => {
          that.getSchoolsCourseware()
        })
      }).catch(() => {
      })
    },
    removeCourseSectionById (row) {
      const that = this
      this.$confirm('是否确定删除该课节？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApicourseManagement.removeCourseSectionById.call(this, { sectionId: row.sectionId }, () => {
          that.getSchoolsLesson()
        })
      }).catch(() => {
      })
    },
    addAndSaveLesson (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const type = this.lessonType === 'add' ? 'addCourseSection' : 'updateCourseSection'
          ApicourseManagement[type].call(this, this.lessonDetails, () => {
            this.lessonDialog = false
            this.lessonDetails = {}
            this.getSchoolsLesson()
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    // 获取课件弹窗信息
    onEditCourseWare (row, type) {
      this.courseWareType = type === 'edit' ? 'edit' : 'add'
      // 校区课件详细
      if (type === 'edit') {
        ApicourseManagement.getCourseSectionsByCourseId.call(this, { courseId: this.schoolsCoursewareId }, (listData) => {
          this.courseList = listData
          this.courseWareDetails = Object.assign({}, {
            courseSection: row.courseSection,
            courseSectionName: row.courseSection.sectionName,
            coursewareContent: row.coursewareContent,
            coursewareName: row.coursewareName,
            coursewareType: row.coursewareType,
            enabled: row.enabled,
            remark: row.remark,
            wareId: row.wareId,
            coursewareUrl: row.coursewareUrl
          })
          this.coursewareUrl = row.coursewareUrl
        })
        this.fileList = [
          {
            name: this.getStringFileName(row.coursewareUrl),
            url: row.coursewareUrl
          }
        ]
      } else {
        ApicourseManagement.getCourseSectionsByCourseId.call(this, { courseId: this.schoolsCoursewareId }, (listData) => {
          this.courseList = listData
          this.courseWareDetails = Object.assign({}, {
            courseSection: {},
            coursewareContent: '',
            coursewareName: '',
            coursewareType: '',
            enabled: false,
            remark: ''
          })
        })
      }
      this.$nextTick(() => {
        this.courseWareDialog = true
      })
    },
    // 编辑弹窗
    onEdit (row, type) {
      this.lessonType = type === 'edit' ? 'edit' : 'add'
      if (type === 'edit') {
        this.lessonDetails = Object.assign({}, {
          sectionId: row.sectionId,
          sectionName: row.sectionName,
          teachingDuration: row.teachingDuration,
          enabled: row.enabled,
          remark: row.remark
        })
      } else {
        this.lessonDetails = Object.assign({}, {
          course: {
            courseId: this.schoolsLessonId
          },
          enabled: false,
          sectionName: '',
          remark: '',
          teachingDuration: 0
        })
      }
      this.lessonDialog = true
    },
    onLessonChangeTree (data) {
      if (data.isLeaf) {
        this.lessonId = data.layerCode
        this.coursewareId = data.layerCode
        this.getLesson()
        this.getCourseware()
        this.getCourseById()
      }
    },
    onCoursewareChangeTree (data) {
      this.categoryId = data.key
      if (data.isLeaf) {
        this.schoolsLessonId = data.layerCode
        this.schoolsCoursewareId = data.layerCode
        this.getSchoolsLesson()
        this.getSchoolsCourseware()
        this.getSchoolsCourseById()
      }
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: {
          resourceType: 'ALL'
        },
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApicourseManagement.pageZoneCourseware.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getPublicRootCourseCategoryTree () {
      ApicourseManagement.getPublicRootCourseCategoryTree.call(this, {}, (listData) => {
        this.rootFolders.push(listData)
      })
    },
    getZoneRootCourseCategoryTree () {
      ApicourseManagement.getZoneRootCourseCategoryTree.call(this, {}, (listData) => {
        this.schoolsRootFolders.push(listData)
      })
    },
    onLoadTree (node, resolve) {
      if (node.data.children) {
        if (node.level === 0) {
          return resolve(this.rootFolders)
        }
        if (node.level === 1) {
          for (const index in this.rootFolders) {
            if (node.data === this.rootFolders[index]) {
              resolve(this.rootFolders[index].children)
            }
          }
        }
      } else {
        if (node.data.isLeaf) {
          resolve([])
        } else {
          if (node.data.type === 'CourseCategory' || node.data.type === 'AdminCourseCategory') {
            ApicourseManagement.getPublicChildCourseCategoryTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else {
            resolve([])
          }
        }
      }
    },
    onSchoolsLoadTree (node, resolve) {
      this.node = node
      this.resolve = resolve
      if (node.level === 0) {
        return resolve(this.schoolsRootFolders)
      }
      if (node.level === 1) {
        for (const index in this.schoolsRootFolders) {
          if (node.data === this.schoolsRootFolders[index]) {
            resolve(this.schoolsRootFolders[index].children)
          }
        }
      } else {
        if (node.data.isLeaf) {
          resolve([])
        } else {
          if (node.data.type === 'CourseCategory' || node.data.type === 'AdminCourseCategory') {
            ApicourseManagement.getChildCourseCategoryTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else {
            resolve([])
          }
        }
      }
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    LessonOnChangeCurrentPage (page) {
      this.getLesson({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    LessonOnChangePageSize (pageSize) {
      this.getLesson({
        pageSize
      })
    },

    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    SchoolsLessonOnChangeCurrentPage (page) {
      this.getSchoolsLesson({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    SchoolsLessonOnChangePageSize (pageSize) {
      this.getSchoolsLesson({
        pageSize
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    CourseOnChangeCurrentPage (page) {
      this.getCourseware({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    CourseOnChangePageSize (pageSize) {
      this.getCourseware({
        pageSize
      })
    },

    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    SchoolsCourseOnChangeCurrentPage (page) {
      this.getSchoolsCourseware({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    SchoolsCourseOnChangePageSize (pageSize) {
      this.getSchoolsCourseware({
        pageSize
      })
    },
    getLesson ({
      page,
      pageSize,
      fn
    } = {}) {
      this.LessonPaginations.current_page = page || 1
      this.LessonPaginations.page_size = pageSize || this.LessonPaginations.page_size
      var data = {
        offset: (this.LessonPaginations.current_page - 1) * this.LessonPaginations.page_size,
        limit: this.LessonPaginations.page_size,
        data: null,
        filters: [{
          field: 'course.courseId',
          operator: 'eq',
          value: this.lessonId
        }],
        orFilters: null,
        order: null,
        sort: null
      }
      ApicourseManagement.pageCourseSection.call(this, data, (listData) => {
        this.LessonTable = listData.content
        this.LessonPaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getSchoolsLesson ({
      page,
      pageSize,
      fn
    } = {}) {
      this.SchoolsLessonPaginations.current_page = page || 1
      this.SchoolsLessonPaginations.page_size = pageSize || this.SchoolsLessonPaginations.page_size
      var data = {
        offset: (this.SchoolsLessonPaginations.current_page - 1) * this.SchoolsLessonPaginations.page_size,
        limit: this.SchoolsLessonPaginations.page_size,
        data: null,
        filters: [{
          field: 'course.courseId',
          operator: 'eq',
          value: this.schoolsLessonId
        }],
        orFilters: null,
        order: null,
        sort: null
      }
      ApicourseManagement.pageCourseSection.call(this, data, (listData) => {
        this.SchoolsLessonTable = listData.content
        this.SchoolsLessonPaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getCourseware ({
      page,
      pageSize,
      fn
    } = {}) {
      this.coursePaginations.current_page = page || 1
      this.coursePaginations.page_size = pageSize || this.coursePaginations.page_size
      var data = {
        offset: (this.coursePaginations.current_page - 1) * this.coursePaginations.page_size,
        limit: this.coursePaginations.page_size,
        data: null,
        filters: [{
          field: 'courseSection.course.courseId',
          operator: 'eq',
          value: this.coursewareId
        }],
        orFilters: null,
        order: null,
        sort: null
      }
      ApicourseManagement.pageCourseware.call(this, data, (listData) => {
        this.CoursewareTable = listData.content
        this.coursePaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getSchoolsCourseware ({
      page,
      pageSize,
      fn
    } = {}) {
      this.SchoolscoursePaginations.current_page = page || 1
      this.SchoolscoursePaginations.page_size = pageSize || this.SchoolscoursePaginations.page_size
      var data = {
        offset: (this.SchoolscoursePaginations.current_page - 1) * this.SchoolscoursePaginations.page_size,
        limit: this.SchoolscoursePaginations.page_size,
        data: null,
        filters: [{
          field: 'courseSection.course.courseId',
          operator: 'eq',
          value: this.schoolsCoursewareId
        }],
        orFilters: null,
        order: null,
        sort: null
      }
      ApicourseManagement.pageCourseware.call(this, data, (listData) => {
        this.SchoolsCoursewareTable = listData.content
        this.SchoolscoursePaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getCourseById () {
      ApicourseManagement.getCourseById.call(this, { courseId: this.lessonId }, (listData) => {
        this.CourseDetails = listData
      })
    },
    getSchoolsCourseById () {
      ApicourseManagement.getCourseById.call(this, { courseId: this.schoolsLessonId }, (listData) => {
        this.SchoolsCourseDetails = listData
      })
    }
  },
  mounted () {
    this.getPublicRootCourseCategoryTree()
    this.getZoneRootCourseCategoryTree()
  }
}
