const tabls = {
  state: {
    router: [],
    currentPath: ''
  },
  mutations: {
    CLEAR_ROUTER (state, text) {
      state.router = text
    },
    SET_LIST (state, text) {
      const flag = state.router.some(
        item => item.path === text.path
      )// 打开标签后，判断数组中是否已经存在该路由
      if (!flag) {
        state.router.push(
          Object.assign(
            {},
            {
              path: text.path,
              name: text.meta.name,
              component: text.name
            }
          )
        )
      }
      state.currentPath = text.path
    },
    DELETE_LIST (state, text) {
      for (const [key, item] of state.router.entries()) {
        if (item.path === text) {
          state.router.splice(key, 1)
          break
        }
      }
    }
  },
  actions: {
    setRouteList (context, text) {
      context.commit('SET_LIST', text)
    },
    deleteRouteList (context, text) {
      context.commit('DELETE_LIST', text)
    },
    clearRoute (context, text) {
      context.commit('CLEAR_ROUTER', text)
    }
  }
}
export default tabls
