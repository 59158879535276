import request from '@/config/request.js'
import setting from '@/config/settings'
const DoubleTeacher = request.DoubleTeacher;
export default {
    name: 'class-management',
    data() {
        return {
            lingjiaoCurrentUser: this.$store.state.login.lingjiaoCurrentUser ? JSON.parse(this.$store.state.login.lingjiaoCurrentUser) : null,
            classRoomData: null,
            qTermYear: '',
            classTypeValue: "",
            classStatus: 'NORMAL',
            searchValue: "",
            search_data: {},
            terminalType: '0',
            classTypesOption: [],
            termYearMerchantList: [],
            classStatusOption: [{
                value: 'NORMAL',
                label: '正常'
            }, {
                value: 'DISABLE',
                label: '禁用'
            }],
            termList: [],
            paginations: {
                current_page: 1,
                total: 0,
                page_size: 10,
                page_sizes: [10, 20, 30],
                layout: 'total, sizes, prev, pager, next, jumper'
            },
            newSchoolYearName: [],
            modalAddPeriodVisible: false,
            terminalList: [],
            classRoomName: '',
            terminalId: '',
            changeyear: false,
            editClassVisible: false,
            editClassCodeVisible: false,
            upgradebVisible: false,
            termYearList: [],
            formLabelWidth: '100px',
            upgradebFormLabelWidth: '160px',
            defalutEduStage: '',
            classItem: {
                name: '',
                classTypeCustomName: '',
                termYear: '',
                term: '',
                description: '',
                classType: '',
            },
            upgradebItem: {
                curName: '',
                curCode: '',
                curTermYear: '',
                curClassType: '',
                classType: '',
                termYear: '',
                curTerm: '',
                terminalUserId: null,
                name: null,
                code: null,
            },
            rules: {
                name: [
                    { required: true, message: '请输入班级名称', trigger: 'blur' }
                ]
            },
            editClassCodeRules: {
                newCode: [
                    { required: true, message: '请输入新的编号', trigger: 'blur' }
                ]
            },
            upgradebRules: {
                termYear: [
                    { required: true, message: '请选择升后班级学年', trigger: 'change' }
                ],
                classType: [
                    { required: true, message: '请选择升后班级班型', trigger: 'change' }
                ],
                term: [
                    { required: true, message: '请选择升后班级学期', trigger: 'change' }
                ],
                code: [
                    { required: false, message: '请输入升后班级编码', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入升后班级名称', trigger: 'blur' }
                ],
            }
        }
    },
    updated() {
    },
    computed: {
        tableHight: function () {
            return window.innerHeight - 255;
        },
        loading: function () {
            return this.$store.state.global.ajax_loading;
        }
    },
    methods: {
        getAllTermList() {
            DoubleTeacher.getAllTermList.call(this, {}, res => {
                if (res && res.success) {
                    this.termList = res.data;
                }
            });
        },
        getClassTypes() {
            DoubleTeacher.findAllBymerchantId.call(this, {merchantId: this.lingjiaoCurrentUser.merchantId}, res => {
                if (res && res.success) {
                    this.classTypesOption = res.data;
                }
            });
        },
        getTermYearMerchantList() {
            const data = {
                merchantId: this.lingjiaoCurrentUser.merchantId,
                isLoadPage: true,
                status: this.classStatus
            };
            DoubleTeacher.getTermYearMerchantList.call(this, data, res => {
                if (res && res.success) {
                    this.termYearMerchantList = res.data;
                    let newSchoolYearName = [];
                    this.termYearMerchantList.map((item, index) => {
                        if (item.isCurrentTermYear == 1) {
                            this.qTermYear = item.termYearCode;
                        }
                        newSchoolYearName[index] = item.name;
                    });
                    this.newSchoolYearName = newSchoolYearName;
                    this.getClassRoomList();
                }
            });
        },
        getClassRoomList() {
            const data = {
                status: this.classStatus,
                name: this.searchValue,
                termYear: this.qTermYear,
                classType: this.classTypeValue,
                pageNo: this.paginations.current_page,
                pageSize: this.paginations.page_size
            };
            DoubleTeacher.getClassRoomList.call(this, data, res => {
                if (res && res.success) {
                    this.classRoomData = res.data;
                    this.paginations.total = res.data.totalSize;
                }
            });
        },
        getTermName(term) {
            const array = this.termList.filter(item => item.term === term);
            if (array.length > 0) {
                return array[0].name;
            }
            return '-';
        },
        uniqueTeachers(val) {
            // 去除重复项并限制到最多3个  
            return [...new Set(val)].slice(0, 3);
        },
        truncateTag(tag) {
            // 截断标签文本  
            if (tag && tag.length >= 10) {
                return tag.substr(0, 10) + '...';
            }
            return tag;
        },
        handleStudentList(item) {

        },
        statusTxt(val) {
            if (val === 'NORMAL') {
                return '正常';
            } else if (val === 'DEL') {
                return '删除';
            } else if (val === 'DISABLE') {
                return '禁用';
            } else {
                return '未知';
            }
        },
        isShow(termYear) {
            let yearArr = termYear.split('-');
            let upTermYear = Number(yearArr[0]) + 1 + '-' + (Number(yearArr[1]) + 1);
            return this.newSchoolYearName.indexOf(upTermYear);
        },
        changeyears() {
            console.log("classItem:", this.classItem);
            DoubleTeacher.updateTerm.call(this, { id: this.classItem.id, term: this.classItem.term }, res => {
                if (res && res.success) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                    this.changeyear = false;
                    this.getClassRoomList();
                }
            });
        },
        updateClassInfo(formName) {
            const that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    DoubleTeacher.updateClassRoom.call(this, {
                        id: this.classItem.id,
                        code: this.classItem.code,
                        educationStage1: this.defalutEduStage,
                        classType: this.classItem.classType,
                        termYear1: this.classItem.termYear + "学年",
                        name: this.classItem.name,
                        description: this.classItem.description
                    }, res => {
                        if (res && res.success) {
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            this.editClassVisible = false;
                            this.getClassRoomList();
                        }
                    })
                }
            });
        },
        updateClassCode(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    DoubleTeacher.updateClassCode.call(this, {
                        curName: this.classItem.name,
                        curCode: this.classItem.code,
                        code: this.classItem.newCode,
                        id: this.classItem.id
                    }, res => {
                        if (res && res.success) {
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            this.editClassCodeVisible = false;
                            this.getClassRoomList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            });
        },
        handleUpgradeClass(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('要升级当前班级吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        DoubleTeacher.upOneClass.call(this, {
                            id: this.classItem.id,
                            code: this.upgradebItem.code,
                            name: this.upgradebItem.name,
                            classType: this.upgradebItem.classType == '通用' ? 0 : this.upgradebItem.classType,
                            termYear: this.upgradebItem.termYear,
                            term: this.upgradebItem.term
                        }, res => {
                            if (res && res.success) {
                                this.upgradebVisible = false;
                                this.getClassRoomList();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }).catch(() => {
                    });
                }
            });
        },
        /**
         * 改变当前页事件
         * @param  {number} page 当前页码
         */
        onChangeCurrentPage(page) {
            this.paginations.current_page = page;
            this.getClassRoomList();
        },
        /**
         * 改变每页显示数量事件
         * @param  {number} size 当前每页显示数量
         */
        onChangePageSize(pageSize) {
            this.paginations.page_size = pageSize;
            this.getClassRoomList();
        },
        handleBinding(item) {
            if (item.terminalUserCode) {
                this.$confirm('要解除当前班级与终端之间的绑定吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    DoubleTeacher.updateTerminalBinding.call(this, { terminalUserId: item.terminalUserId, classroomId: item.id }, res => {
                        if (res && res.success) {
                            this.getClassRoomList();
                        }
                    });
                }).catch(() => {

                });
            } else {
                DoubleTeacher.getTerminalList.call(this, { property: this.terminalType, status: 'NORMAL' }, res => {
                    if (res && res.success) {
                        this.terminalList = res.data;
                    }
                });
                this.classRoomId = item.id;
                this.classRoomName = item.name;
                this.modalAddPeriodVisible = true;
            }
        },
        createModalAddPeriod() {
            if (this.terminalId === '') {
                this.$message({
                    type: 'warning',
                    message: '您还没有选择终端'
                });
            } else {
                DoubleTeacher.bindClassRoom.call(this, { terminalUserId: this.terminalId, classroomId: this.classRoomId }, res => {
                    if (res && res.success) {
                        this.getClassRoomList();
                    }
                });
                this.terminalId = '';
                this.modalAddPeriodVisible = false;
            }
        },
        handleMore(key, item) {
            console.log("key:", key, item);
            this.classItem = item;
            if (key === 'timetable') {
                // 在某个组件中  
                this.$store.commit('setCurClassData', item);
                this.$router.push({ name: 'classManagement/timeTable' });
            } else if (key === 'edit') {
                this.getEducationStage();
                this.editClassVisible = true;
            } else if (key === 'year') {
                this.getTermYearList(item, () => {
                    this.changeyear = true;
                });
            } else if (key === 'status') {
                const userObj = {};
                userObj.id = item.id;
                let contentTxt = '';
                if (item.status === 'DISABLE') {
                    contentTxt = '要启用当前班级吗？';
                    userObj.status = 0;
                    userObj.opRes = '启用班级成功';
                } else if (item.status === 'NORMAL') {
                    contentTxt = '要禁用当前班级吗？';
                    userObj.status = 2;
                    userObj.opRes = '禁用班级成功';
                } else {
                    this.$message.error('出错了，请联系管理员');
                }

                this.$confirm(contentTxt, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    DoubleTeacher.updateClassRoom.call(this, userObj, res => {
                        if (res && res.success) {
                            this.$message({
                                message: userObj.opRes,
                                type: 'success'
                            });
                            this.getClassRoomList();
                        }
                    });
                }).catch(() => {
                });
            } else if (key === 'code') {
                this.editClassCodeVisible = true;
            } else if (key === 'lock') {
                this.$message({
                    message: '该功能暂未开放',
                    type: 'warning'
                });
            } else if (key === 'upgradeb') {
                this.getTermYearList(item, () => { });
                const foundItem = this.termList.find(term => term.term === item.term);
                const nextTermYear = this.getNextTermYear(item.termYear);
                this.upgradebItem = {
                    curName: item.name,
                    curCode: item.code,
                    curTermYear: item.termYear,
                    curClassType: '通用',
                    curTerm: foundItem?.name,
                    classType: '通用',
                    termYear: nextTermYear
                };
                this.upgradebVisible = true;
            } else if (key === 'upgrade') {
                this.getTermYearList(item, () => { });
                const foundItem = this.termList.find(term => term.term === item.term);
                const nextTermYear = this.getNextTermYear(item.termYear);
                const nextClassType = this.getNextClassType(item.classType);
                this.upgradebItem = {
                    curName: item.name,
                    curCode: item.code,
                    curTermYear: item.termYear,
                    curClassType: item.classType,
                    curTerm: foundItem?.name,
                    classType: nextClassType,
                    termYear: nextTermYear
                };
                this.upgradebVisible = true;
            } else if (key === 'delete') {
                this.$confirm('要删除当前班级吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const userObj = {};
                    userObj.id = item.id;
                    userObj.status = 1;
                    userObj.opRes = '删除班级成功';
                    DoubleTeacher.updateClassRoom.call(this, userObj, res => {
                        if (res && res.success) {
                            this.$message({
                                message: userObj.opRes,
                                type: 'success'
                            });
                            this.getClassRoomList();
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }).catch(() => {
                });
            }
        },
        getTermYearList(item, rtnBack) {
            DoubleTeacher.getTermMerchant.call(this, {
                merchantId: this.lingjiaoCurrentUser?.merchantId,
                param: { termYearCode: item.termYear }
            }, res => {
                if (res && res.success) {
                    this.termYearList = res.data;
                    rtnBack();
                }
            });
        },
        getNextClassType(classType) {
            let index = 0;
            for (let i = 0; i < this.classTypesOption.length; i++) {
                if (this.classTypesOption[i].classType == classType) {
                    index = i;
                }
            }
            let nextClassType = '';
            if (index < this.classTypesOption.length) {
                nextClassType = this.classTypesOption[index + 1].classType;
                return nextClassType;
            }
        },
        getEducationStage() {
            DoubleTeacher.getEducationStage.call(this, {}, res => {
                if (res && res.success) {
                    const educationStageList = res.data;
                    if (educationStageList && this.lingjiaoCurrentUser?.educationStage) {
                        const foundStage = educationStageList.find(stage => stage.educationStage === this.lingjiaoCurrentUser.educationStage);
                        if (foundStage) {
                            this.defalutEduStage = foundStage.name;
                        }
                    }
                }
            });
        },
        getNextTermYear(termYear) {
            if (!termYear) {
                return false;
            }
            let termYearArr = termYear.split('-');

            return parseInt(termYearArr[0]) + 1 + '-' + (parseInt(termYearArr[1]) + 1);
        }
    },
    mounted() {
        this.getClassTypes();
        this.getAllTermList();
        this.getTermYearMerchantList();
    }
}
