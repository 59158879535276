import request from '@/config/request.js'
import pdf from 'vue-pdf'
const ApiTeacherAssessment = request.teacherAssessment
export default {
  name: 'teacher-assessment',
  components: {
    pdf
  },
  data () {
    return {
      formLabelWidth: '90px',
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tabSum: ['月度考评', '师训评估', '通用技能'],
      sumActive: 0,
      tabSubject: ['全部', '语文', '数学', '英语'],
      SubjectActive: 0,
      tabVersion: ['全部', '人教版', '北师版', '苏教版', '冀教版', '青岛版'],
      VersionActive: 0,
      tabGrade: ['全部', '一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
      GradeActive: 0,
      tabProject: ['全部', '妙习字', '写作', '幼小', '专注力', '安亲岗前培训', '其他'],
      ProjectActive: 0,
      tabModule: ['全部', '学前测评', '结业考核'],
      ModuleActive: 0,
      tabModuleOne: ['全部', '招聘面试', '职业技能', '其他'],
      ModuleOneActive: 0,
      selectValue: ['月度考评', '全部', '全部', '全部'],
      titleSum: [
        {
          value: '月度考评',
          children: [
            {
              title: '学科',
              cheldren: [
                {
                  value: '全部',
                  data: ['全部', '人教版', '北师版', '苏教版', '冀教版', '青岛版']
                }, {
                  value: '语文',
                  data: []
                }, {
                  value: '数学',
                  data: ['全部', '人教版', '北师版', '苏教版', '冀教版', '青岛版']
                }, {
                  value: '英语',
                  data: []
                }
              ]
            },
            {
              title: '年级',
              data: ['全部', '一年级', '二年级', '三年级', '四年级', '五年级', '六年级']
            }
          ]
        },
        {
          value: '师训评估',
          children: [
            {
              title: '项目',
              cheldren: [{
                value: '全部',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '妙习字',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '写作',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '幼小',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '专注力',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '安亲岗前培训',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }, {
                value: '其他',
                chlidren: {
                  title: '模块',
                  data: ['全部', '学前测评', '结业考核']
                }
              }]
            }
          ]
        },
        {
          value: '通用技能',
          children: [
            {
              title: '模块',
              data: ['全部', '招聘面试', '职业技能', '其他']
            }
          ]
        }
      ],
      tableList: [],
      dataTitle: [],
      dataCate: [],
      search: {
        categoryPath: [],
        paperName: ''
      },
      getIndex: [],
      pdfUrl: '',
      pdfView: false,
      pageNum: 1,
      pageTotalNum: 1,
      loadedRatio: 0,
      downUrl: '',
      teacherDailog: false,
      zoneTeacher: [],
      submitForm: {
        teacherScore: [
          {
            zoneTeacher: {
              teacherId: ''
            },
            score: '',
            paper: {
              paperId: ''
            },
            remark: ''
          }
        ]
      },
      teacherScoreDailog: false,
      teacherScoreTable: [],
      search_data: {
        filters: [{
          field: 'paperName',
          operator: 'like',
          value: ''
        }]
      },
      dataList: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 400
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getTeacherLook (row) {
      ApiTeacherAssessment.getPerformance.call(this, { paperId: row.paperId }, (res) => {
        this.teacherScoreTable = res
        this.$nextTick(() => {
          this.teacherScoreDailog = true
        })
      })
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiTeacherAssessment.addPerformanceList.call(this, this.submitForm.teacherScore, () => {
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            // this.$refs[formName].resetFields()
            this.getList()
            this.teacherDailog = false
            this.submitForm = {
              teacherScore: [
                {
                  zoneTeacher: {
                    teacherId: ''
                  },
                  score: '',
                  paper: {
                    paperId: ''
                  },
                  remark: ''
                }
              ]
            }
          })
        }
      })
    },
    delTeahcerList (index) {
      this.teacherScore.splice(index, 1)
    },
    addTeacherList () {
      this.submitForm.teacherScore.push({
        zoneTeacher: {
          teacherId: ''
        },
        score: '',
        paper: {
          paperId: this.paperId
        },
        remark: ''
      })
    },
    getTeacherInsert (row) {
      this.paperId = row.paperId
      this.submitForm.teacherScore[0].paper.paperId = row.paperId
      this.getzoneTeacher()
      this.$nextTick(() => {
        this.teacherDailog = true
      })
    },
    getView (row) {
      ApiTeacherAssessment.addDownloadNum.call(this, { paperId: row.paperId }, () => {

      })
      const url = decodeURI(encodeURI(row.paperUrl))
      this.downUrl = url
      if (/\.(doc|docx|ppt|pptx|xls|xlsx)$/.test(url)) {
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' + url, '_blank'
        )
        return false
      } else {
        window.open(url)
      }
    },
    getViewAnswer (row) {
      const url = decodeURI(encodeURI(row.answerUrl))
      if (/\.(doc|docx|ppt|pptx|xls|xlsx)$/.test(url)) {
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' + url, '_blank'
        )
        return false
      } else {
        window.open(url)
      }
    },
    // 上一页
    prePage () {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage () {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    // 默认写死，后期需要可以通过点击循环titleSum 来获取当前下面的子节点，也依次写死
    // setValueTag (data, index, e) {
    //   for (const i in data) {
    //     if (data[i].title === e.target.dataset.type) {
    //
    //     }
    //   }
    // },
    // selectTagFileBases (e) {
    //   if (e.target.dataset.type === '类别') {
    //     this.sumActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '学科') {
    //     this.SubjectActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '版本') {
    //     this.VersionActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '年级') {
    //     this.GradeActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '项目') {
    //     this.ProjectActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '模块') {
    //     this.ModuleActive = e.target.dataset.index
    //   }
    //   if (e.target.dataset.type === '模块1') {
    //     this.ModuleOneActive = e.target.dataset.index
    //   }
    // }
    getTopCategory (fn) {
      ApiTeacherAssessment.getTopCategory.call(this, {}, (item) => {
        this.dataTitle = item
        if (fn && typeof fn === 'function') {
          if (item.length > 0) {
            this.search.categoryPath = [item[0].categoryId]
            fn(item[0].categoryId)
          }
        }
      })
    },
    getzoneTeacher () {
      ApiTeacherAssessment.getZoneTeachers.call(this, {}, (item) => {
        this.zoneTeacher = item
      })
    },
    getCategory (id) {
      ApiTeacherAssessment.getCategory.call(this, { categoryId: id }, (item) => {
        this.dataList = []
        this.search.categoryPath = [id]
        this.getIndex = []
        for (const i in item) {
          switch (i) {
            case '学科':
              this.dataList = [...this.dataList, {
                prop: 'paperCategoryMap.XK.categoryName',
                name: '学科'
              }]
              break
            case '版本':
              this.dataList = [...this.dataList, {
                prop: 'paperCategoryMap.BB.categoryName',
                name: '版本'
              }]
              break
            case '年级':
              this.dataList = [...this.dataList, {
                prop: 'paperCategoryMap.NJ.categoryName',
                name: '年级'
              }]
              break
            case '项目':
              this.dataList = [...this.dataList, {
                prop: 'paperCategoryMap.XM.categoryName',
                name: '项目'
              }]
              break
            case '模块':
              if (id === 2) {
                this.dataList = [...this.dataList, {
                  prop: 'paperCategoryMap.MK.categoryName',
                  name: '模块'
                }]
              } else {
                this.dataList = [...this.dataList, {
                  prop: 'paperCategoryMap.TYJN_MK.categoryName',
                  name: '模块'
                }]
              }

              break
          }
          item[i] = [{ categoryId: '-1', categoryName: '全部' }, ...item[i]]
          this.search.categoryPath = [...this.search.categoryPath, item[i][0].categoryId]
          this.getIndex = [...this.getIndex, i]
        }
        this.dataCate = item
        this.$nextTick(() => {
          this.getList()
        })
      })
    },
    getIndexValue (name) {
      return this.getIndex.indexOf(name) + 1
    },
    getCategorySelect (e) {
      this.search.categoryPath[this.getIndexValue(e.target.dataset.type)] = e.target.dataset.index
      this.$nextTick(() => {
        this.getList()
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      let categoryPath = [...this.search.categoryPath]
      for (let i = 0; i < categoryPath.length; i++) {
        if (categoryPath[i] < 0) {
          categoryPath.splice(i, 1)
          i--
        }
      }
      categoryPath = categoryPath.join()
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: {
          categoryPath: categoryPath
        },
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiTeacherAssessment.getPapers.call(this, data, (item) => {
        this.tableList = item.content
        this.paginations.total = parseInt(item.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getTopCategory(this.getCategory)
  }
}
