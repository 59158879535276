<template>
  <div class="drag-container">
    <div class="drag-item" ref="dragItem" @mousedown="onMouseDown" :style="{left: dragLeft + 'px', top: dragTop + 'px'}">
      Drag me!
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dragStartX: 0,
      dragStartY: 0,
      dragLeft: 0,
      dragTop: 0,
      dragging: false
    }
  },
  methods: {
    onMouseDown(e) {
      this.dragging = true
      this.dragStartX = e.clientX
      this.dragStartY = e.clientY
      this.dragLeft = this.$refs.dragItem.offsetLeft
      this.dragTop = this.$refs.dragItem.offsetTop
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
    },
    onMouseMove(e) {
      if (this.dragging) {
        const diffX = e.clientX - this.dragStartX
        const diffY = e.clientY - this.dragStartY
        this.dragLeft += diffX
        this.dragTop += diffY
        this.dragStartX = e.clientX
        this.dragStartY = e.clientY
      }
    },
    onMouseUp() {
      this.dragging = false
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    }
  }
}
</script>

<style>
.drag-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.drag-item {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: yellow;
  cursor: pointer;
}
</style>
