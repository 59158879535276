import request from '@/config/request.js'
import setting from '@/config/settings'
import { Message } from 'element-ui'

import Modules from '@/components/modules'
import RouteView from '@/components/routeView'
// import store from "@/store";
const ApiLogin = request.UserLogin
const DoubleTeacher = request.DoubleTeacher;
export default {
  name: 'login',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.resetlogin.confirmPassword !== '') {
          this.$refs.resetPass.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请重复输入新密码'))
      } else if (value !== this.resetlogin.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      qiniu: setting.gbs.qiniu,
      login: {
        mobile: this.$store.state.login.logininfo.loginName,
        password: this.$store.state.login.logininfo.loginPwd,
        validCodeKey: '',
        validCodeValue: '',
        rememberMe: false
      },
      resetlogin: {
        mobile: '',
        newPassword: '',
        confirmPassword: '',
        code: ''
      },
      loginRules: {
        mobile: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        validCodeValue: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      checked: true,
      loading: false,
      codeChange: '',
      changeTeacher: false,
      changeTeacherList: [],
      appUrl: setting.gbs.host,
      changeItem: '',
      teacherID: '',
      codeUrl: '',
      loginType: 'pass',
      number: localStorage.getItem('bell-min') ? localStorage.getItem('bell-min') : -1,
      resetnumber: localStorage.getItem('bell-reset-min') ? localStorage.getItem('bell-reset-min') : -1,
      resetPassDialog: false,
      passRules: {
        mobile: [
          { required: true, message: '请输入新账号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      shuangShiMenu: {
        "id": "90",
        "parentId": "0",
        "name": "全能双师",
        "path": "/double-teacher",
        "icon": "bell-icon-shuangshiketang",
        "component": "RouteView.RouteIndex.Index",
        "keepAlive": false,
        "singleMenu": false,
        "sort": 16,
        "isShow": true,
        "children": [
          {
            "id": "91",
            "parentId": "90",
            "name": "双师账号",
            "path": "/accountManagement",
            "component": "Modules.DoubleTeacher.accountManagement",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": true
          },
          {
            "id": "92",
            "parentId": "90",
            "name": "账号权限",
            "path": "/accountPermission",
            "component": "Modules.DoubleTeacher.accountPermission",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": true
          },
          {
            "id": "93",
            "parentId": "90",
            "name": "班级管理",
            "path": "/classManagement",
            "component": "Modules.DoubleTeacher.classManagement",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": true
          },
          {
            "id": "94",
            "parentId": "93",
            "name": "班级课程表",
            "path": "/classManagement/timeTable",
            "component": "Modules.DoubleTeacher.timeTable",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": false
          },
          {
            "id": "95",
            "parentId": "93",
            "name": "班级学期课程表",
            "path": "/classManagement/timeTablePeriod",
            "component": "Modules.DoubleTeacher.timeTablePeriod",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": false
          },
          {
            "id": "96",
            "parentId": "93",
            "name": "班级周课程表",
            "path": "/classManagement/timeTableWeek",
            "component": "Modules.DoubleTeacher.timeTableWeek",
            "keepAlive": false,
            "singleMenu": false,
            "sort": 17,
            "isShow": false
          },
        ]
      }
    }
  },
  methods: {
    // 重置密码
    onResetPassWord(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          ApiLogin.resetPassword.call(this, { mobile: this.resetlogin.mobile, password: this.resetlogin.newPassword, authCode: this.resetlogin.code }, item => {
            this.resetnumber = -1
            this.resetPassDialog = false
            Message({
              message: item.result,
              type: 'success'
            })
          })
        }
      })
    },
    onChangeLoginType(type) {
      this.loginType = type
      this.$refs.loginruleForm.validate((valid) => {
      })
    },
    componentsMatch(array) {
      return new Promise((resolve) => {
        let arr = []
        arr = array.filter(item => {
          if (Object.prototype.hasOwnProperty.call(item, 'children')) {
            const list = item.component.split('.')
            let module = ''
            if (item.component.indexOf('RouteView') > -1) {
              for (let i = 1; i < list.length; i++) {
                module = module ? module[list[i]] : RouteView[list[i]]
              }
            } else if (item.component.indexOf('Modules') > -1) {
              for (let i = 1; i < list.length; i++) {
                module = module ? module[list[i]] : Modules[list[i]]
              }
            }
            item.component = module
            item.children.filter((it) => {
              const childComponent = it.component.split('.')
              let childmodule = ''
              if (it.component.indexOf('RouteView') > -1) {
                for (let i = 1; i < childComponent.length; i++) {
                  childmodule = childmodule ? childmodule[childComponent[i]] : RouteView[childComponent[i]]
                }
              } else if (it.component.indexOf('Modules') > -1) {
                for (let i = 1; i < childComponent.length; i++) {
                  childmodule = childmodule ? childmodule[childComponent[i]] : Modules[childComponent[i]]
                }
              }
              it.component = childmodule
            })
          }
          return item
        })
        resolve(arr)
      })
    },
    routeMatch(array) {
      return new Promise((resolve) => {
        // return resolve([])
        let arr = []
        arr = array.map(item => {
          const listSplit = item.component.split('.')
          let module = ''
          if (item.component.indexOf('RouteView') > -1) {
            for (let i = 1; i < listSplit.length; i++) {
              module = module ? module[listSplit[i]] : RouteView[listSplit[i]]
            }
          } else if (item.component.indexOf('Modules') > -1) {
            for (let i = 1; i < listSplit.length; i++) {
              module = module ? module[listSplit[i]] : Modules[listSplit[i]]
            }
          }
          let listArr = []
          listArr = item.children.map(list => {
            let module = ''
            const splita = list.component.split('.')
            if (list.component.indexOf('RouteView') > -1) {
              for (let i = 1; i < splita.length; i++) {
                module = module ? module[splita[i]] : RouteView[splita[i]]
              }
            } else if (list.component.indexOf('Modules') > -1) {
              for (let i = 1; i < splita.length; i++) {
                module = module ? module[splita[i]] : Modules[splita[i]]
              }
            }
            return {
              path: this.getSrtSubstring(list.path),
              name: this.getSrtSubstring(list.path),
              meta: {
                name: list.name
              },
              component: module
            }
          })
          if (listArr.length > 0) {
            listArr = [{
              path: '/',
              redirect: to => {
                const str = listArr[0].path
                if (str.indexOf('/') > -1) {
                  return str.substr(1)
                } else {
                  return str
                }
              },
              hidden: true
            }, ...listArr]
          }
          return {
            path: item.path,
            meta: {
              name: item.name,
              keepAlive: true
            },
            component: module,
            children: listArr,
            name: item.path
          }
        })
        resolve(arr)
      })
    },
    getRouterArray(dataMenu) {
      let arr = []
      if (dataMenu && dataMenu.length < 1) {
        arr = []
      }
      if (dataMenu && dataMenu.length > 0) {
        arr = dataMenu.map(list => {
          return {
            text: list.name,
            icon: list.icon,
            path: list.path,
            isShow: list.isShow,
            component: list.component,
            children: list.children && list.children.map(child => {
              return {
                path: child.path,
                text: child.name,
                isShow: child.isShow,
                component: child.component
              }
            })
          }
        })
      }
      return arr
    },
    getTeacherLogin(teacher = []) {
      ApiLogin.getTeacherAccountInfoByTeacherId.call(this, { teacherId: teacher[0].teacherId }, item => {
        this.$store.dispatch('setUserInfo', JSON.stringify(item))
        ApiLogin.getMenus.call(this, {}, dataMenu => {
          this.lingjiaoVerifyAccount((result) => {
            if (result) {
              dataMenu.push(this.shuangShiMenu);
            }
            if (dataMenu.length === 0) {
              Message({
                message: '未分配任何菜单权限，请联系管理员！',
                type: 'error'
              })
            }
            this.setMenu(dataMenu).then(() => {
              this.routeMatch(JSON.parse(this.$store.state.login.rootMenu)).then(rt => {
                this.$router.options.routes = this.$router.options.routes.concat(rt)
                this.$router.addRoutes(rt)
                for (const router in dataMenu) {
                  if (dataMenu[router].children.length > 0) {
                    this.$nextTick(() => {
                      const menuPath = dataMenu[router].children[0].path
                      const path = menuPath
                      this.$router.push({
                        path: dataMenu[router].path + path
                      })
                    })
                    break
                  } else {
                    this.$nextTick(() => {
                      this.$router.push({
                        path: dataMenu[router].path
                      })
                    })
                  }
                }
              })
            })
          });
        })
      })
    },
    loginIn(loginruleForm) {
      const that = this
      this.$store.dispatch('schoolsTeacher', JSON.stringify([]))
      this.$refs[loginruleForm].validate((valid) => {
        if (valid) {
          if (this.loginType === 'pass') {
            ApiLogin.login.call(this, { mobile: that.login.mobile, password: that.login.password }, res => {
              this.$store.dispatch('loginIn', 'Bearer ' + res.token)
              ApiLogin.listZoneTeacher.call(this, {}, teacher => {
                if (teacher.length > 1) {
                  this.$store.dispatch('schoolsTeacher', JSON.stringify(teacher))
                  that.changeTeacherList = teacher
                  that.changeTeacher = true
                } else {
                  this.$store.dispatch('setZoneId', teacher[0].schoolZone.zoneId)
                  this.$store.dispatch('setZoneTeacher', JSON.stringify(teacher[0]))
                  ApiLogin.zoneFeatureIsBroadcast.call(this, {}, item => {
                    this.$store.dispatch('setOtoStatus', item)
                  })
                  this.getTeacherLogin(teacher)
                }
              })
            })
          } else {
            ApiLogin.loginWithCode.call(this, { mobile: that.login.mobile, authCode: that.login.validCodeValue }, res => {
              this.$store.dispatch('loginIn', 'Bearer ' + res.token)
              ApiLogin.listZoneTeacher.call(this, {}, teacher => {
                if (teacher.length > 1) {
                  this.$store.dispatch('schoolsTeacher', JSON.stringify(teacher))
                  that.changeTeacherList = teacher
                  that.changeTeacher = true
                } else {
                  this.$store.dispatch('setZoneId', teacher[0].schoolZone.zoneId)
                  this.$store.dispatch('setZoneTeacher', JSON.stringify(teacher[0]))
                  ApiLogin.zoneFeatureIsBroadcast.call(this, {}, item => {
                    this.$store.dispatch('setOtoStatus', item)
                  })
                  this.getTeacherLogin(teacher)
                }
              })
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    setMenu(menu) {
      return new Promise((resolve) => {
        this.$store.dispatch('setRootMenu', JSON.stringify(menu))
        resolve()
      })
    },
    getSrtSubstring(path) {
      if (path.indexOf('/') > -1) {
        return path.substr(1)
      } else {
        return path
      }
    },
    getTeacher() {
      ApiLogin.changeLoginZoneTeacher2.call(this, { zoneTeacherId: this.teacherID }, (res) => {
        this.$store.dispatch('loginIn', 'Bearer ' + res.token)
        this.$store.dispatch('setZoneId', res.zoneTeacher.schoolZone.zoneId)
        this.$store.dispatch('setZoneTeacher', JSON.stringify(res.zoneTeacher))
        this.$nextTick(() => {
          ApiLogin.zoneFeatureIsBroadcast.call(this, {}, item => {
            this.$store.dispatch('setOtoStatus', item)
          })
          ApiLogin.getTeacherAccountInfoByTeacherId.call(this, { teacherId: this.teacherID }, item => {
            this.$store.dispatch('setUserInfo', JSON.stringify(item))
            ApiLogin.getMenus.call(this, {}, dataMenu => {
              this.lingjiaoVerifyAccount((result) => {
                if (result) {
                  dataMenu.push(this.shuangShiMenu);
                }
                if (dataMenu.length === 0) {
                  Message({
                    message: '未分配任何菜单权限，请联系管理员！',
                    type: 'error'
                  })
                }

                this.setMenu(dataMenu).then(() => {
                  this.routeMatch(JSON.parse(this.$store.state.login.rootMenu)).then(rt => {
                    this.$router.options.routes = this.$router.options.routes.concat(rt)
                    this.$router.addRoutes(rt)
                    for (const router in dataMenu) {
                      if (dataMenu[router].children.length > 0) {
                        this.$nextTick(() => {
                          const menuPath = dataMenu[router].children[0].path
                          const path = menuPath
                          this.$router.push({
                            path: dataMenu[router].path + path
                          })
                        })
                        break
                      } else {
                        this.$nextTick(() => {
                          this.$router.push({
                            path: dataMenu[router].path
                          })
                        })
                      }
                    };
                  })
                })
              });
            })
          })
        })
      })
    },
    changeRadio(e) {
      this.teacherID = e
    },
    validCodeChange() {
      const randomKey = new Date().getTime() + Math.random()
      this.login.validCodeKey = randomKey
      this.$nextTick(() => {
        this.login.validCodeKey = randomKey
        this.codeUrl = this.appUrl + 'app/rest/school/green/security/validCodeKey?key=' + randomKey
      })
    },
    resetloginValidateCodeByManager(fn) {
      ApiLogin.loginValidateCodeByManager.call(this, { mobile: this.resetlogin.mobile }, item => {
        Message({
          message: item.result,
          type: 'success'
        })
        fn()
      })
    },
    loginValidateCodeByManager(fn) {
      ApiLogin.loginValidateCodeByManager.call(this, { mobile: this.login.mobile }, item => {
        Message({
          message: item.result,
          type: 'success'
        })
        fn()
      })
    },
    changeMine() {
      this.number = this.number - 1
      localStorage.setItem('bell-min', this.number)
    },
    resetChangeMine() {
      this.resetnumber = this.resetnumber - 1
      localStorage.setItem('bell-reset-min', this.resetnumber)
    },
    sendResetCodeChange() {
      const that = this
      if (that.resetnumber > -1) {
        return
      }
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (reg.test(that.resetlogin.mobile) === false) {
        Message({
          message: '请输入正确的手机号码',
          type: 'error'
        })
      } else {
        that.resetloginValidateCodeByManager(() => {
          that.resetnumber = 60
          const min = setInterval(function () {
            if (that.resetnumber > -1) {
              that.resetChangeMine()
            } else {
              clearInterval(min)
            }
          }, 1000)
        })
      }
    },
    sendCodeChange() {
      const that = this
      if (that.number > -1) {
        return
      }
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (reg.test(that.login.mobile) === false) {
        Message({
          message: '请输入正确的手机号码',
          type: 'error'
        })
      } else {
        that.loginValidateCodeByManager(() => {
          that.number = 60
          const min = setInterval(function () {
            if (that.number > -1) {
              that.changeMine()
            } else {
              clearInterval(min)
            }
          }, 1000)
        })
      }
    },
    lingjiaoVerifyAccount(rtnBack) {
      ApiLogin.lingjiaoVerifyAccount.call(this, {}, res => {
        if (res && res.success) {
          DoubleTeacher.login.call(this, res.data, response => {
            if (response && response.success) {
              this.$store.dispatch('setLingjiaoCurrentUser', JSON.stringify(response.data));
              rtnBack(true);
            } else {
              rtnBack(false);
            }
          });
        } else {
          rtnBack(false);
        }
      });
    }
  },
  mounted() {
    const that = this
    const min = setInterval(function () {
      if (that.number > -1) {
        that.changeMine()
      } else {
        clearInterval(min)
      }
    }, 1000)
    const restMin = setInterval(function () {
      if (that.resetnumber > -1) {
        that.resetChangeMine()
      } else {
        clearInterval(restMin)
      }
    }, 1000)
  }
}
