export default {
  // 获取指定数据
  partData (basicData, fields) {
    var returnData = {}
    if (typeof fields === 'object') {
      fields.map(item => {
        if (basicData[item] === null) {
          basicData[item] = ''
        }
        if (typeof item === 'object') {
          for(let k in item) {
            if (typeof  item[k]  === 'object') {
              returnData[k] = item[k][k]
            }
            else if (basicData[item[k]] !== null) {
              returnData[k] = basicData[item[k]]
            }
          }
        } else {
          returnData[item] = basicData[item]
        }
      })
    }
    return returnData
  }
}
