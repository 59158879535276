import classManagement from './classManagement/index.vue'
import order from './orderService/index.vue'
import studentAttendance from './studentAttendance/index.vue'
import student from './studentManagement/'
import teacherManagement from './teacherManagement/index.vue'
import teacherAttendance from './teacherAttendance/index.vue'
import teacherResignation from './teacherResignation/index.vue'
export default {
  classManagement,
  order,
  studentAttendance,
  student,
  teacherManagement,
  teacherAttendance,
  teacherResignation
}
