import accountManagement from './accountManagement/index.vue';
import accountPermission from './accountPermission/index.vue';
import classManagement from './classManagement/index.vue';
import timeTable from './classManagement/timeTable/index.vue';
import timeTablePeriod from './classManagement/timeTablePeriod/index.vue';
import timeTableWeek from './classManagement/timeTableWeek/index.vue';

export default {
    accountManagement,
    accountPermission,
    classManagement,
    timeTable,
    timeTablePeriod,
    timeTableWeek
}