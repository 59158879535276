import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiFile = request.File
export default {
  name: 'admissions',
  data () {
    return {
      qiniu: setting.gbs.qiniu,
      search_data: {
        filters: [{
          field: 'semesters',
          operator: 'eq',
          value: ''
        }, {
          field: 'fileTags',
          operator: 'eq',
          value: ''
        }, {
          field: 'baseId',
          operator: 'eq',
          value: ''
        }, {
          field: 'packName',
          operator: 'like',
          value: ''
        }, {
          field: 'parentCategoryId',
          operator: 'eq',
          value: ''
        }]
      },
      formLabelWidth: '90px',
      tableList: [],
      allEnabledFileBases: [],
      allSemesters: [],
      allFileTag: [],
      checkList: [],
      baseId: '',
      semesterId: '',
      tagId: '',
      lastFilePacks: [],
      hotFilePacks: [],
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 215
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  watch: {
    baseId: function (str) {
      if (str !== '') {
        this.checkList = []
      }
    }
  },
  methods: {
    getDetails (packId) {
      this.$router.push({ path: `/file/admissions/details/${packId}` })
    },
    selectTagFileBases (e) {
      this.baseId = e.target.dataset.value
      this.search_data.filters[2].value = e.target.dataset.value
      this.search_data.filters[1].value = ''
      this.tagId = ''
      this.getAllFileTag()
      this.onSearch()
    },
    getAllEnabledFileBases () {
      ApiFile.getAllEnabledFileBases.call(this, {}, (listData) => {
        this.allEnabledFileBases = listData
      })
    },
    selectTagSemesters (e) {
      this.semesterId = e.target.dataset.value
      this.search_data.filters[0].value = e.target.dataset.value
    },
    getAllSemesters () {
      ApiFile.getAllSemesters.call(this, {}, (listData) => {
        this.allSemesters = listData
      })
    },
    selectTag (e) {
      this.tagId = e.target.dataset.value
      this.search_data.filters[1].value = e.target.dataset.value
      this.onSearch()
    },
    getAllFileTag () {
      ApiFile.getAllFileTag.call(this, { baseId: this.baseId }, (listData) => {
        this.allFileTag = listData
      })
    },
    getLastFilePacks () {
      ApiFile.getLastFilePacks.call(this, { baseId: this.baseId }, (listData) => {
        this.lastFilePacks = listData
      })
    },
    getHotFilePacks () {
      ApiFile.getHotFilePacks.call(this, { baseId: this.baseId }, (listData) => {
        this.hotFilePacks = listData
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      // var sd = {}
      // var query = this.$route.query
      // for (var p in query) {
      //   sd[p] = query[p]
      // }
      // const newQuery = {} // 深拷贝
      // this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      this.paginations.current_page = page || 1
      this.paginations.page_size = pageSize || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: null,
        sort: null
      }
      if (!where) {
        where = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            where.push(this.search_data.filters[s])
          }
        }
      }
      data.filters = where
      ApiFile.pageFilePackModel.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }

  },
  mounted () {
    this.getAllEnabledFileBases()
    this.getAllSemesters()
    this.getLastFilePacks()
    this.getHotFilePacks()
    this.getList()
  }
}
