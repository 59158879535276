import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiStudentManagement = request.StudentManagement
const ApiClassManagement = request.ClassManagement
export default {
  name: 'student-management',
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码！'))
      }
      setTimeout(() => {
        var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }, 1000)
    }
    return {
      seachShow: false,
      edit: '',
      qiniu: setting.gbs.qiniu,
      urlHost: setting.gbs.host,
      search_data: {
        filters: [{
          field: 'student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'student.grade',
          operator: 'eq',
          value: ''
        }, {
          field: 'student.gender',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: ''
        }, {
          field: 'expiry',
          operator: 'eq',
          value: ''
        }, {
          field: 'studentCode',
          operator: 'like',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      studyClassId: '',
      studyClass: [],
      tableList: [],
      DetailFormVisible: false,
      dateDetail: {},
      formLabelWidth: '120px',
      // 添加学生
      DetailFormVisibleAdd: false,
      imageUrl: '',
      imageUrlName: '',
      imageUrl1: '',
      imageUrl1Name: '',
      myHeaders: { Authorization: this.$store.state.login.token },
      services: [],
      studentDetail: {
        checked: false,
        studentId: 0,
        expiryDate: '',
        studyCurriculumIds: [],
        enrollmentDate: '',
        student: {
          letter: '',
          studentName: '',
          birthday: '',
          address: '',
          avatarImg: '',
          gender: true,
          grade: '',
          school: null,
          schoolClass: ''
        },
        servicesArr: [],
        services: '',
        studentParents: [
          // {
          //   accountId: 0,
          //   mobile: '',
          //   relationship: '',
          //   parentName: '',
          //   avatarImg: '',
          //   standbyMobile: ''
          // }
        ],
        remark: ''
      },
      // del: [
      //   {
      //     name: '是',
      //     value: true
      //   }, {
      //     name: '否',
      //     value: false
      //   }
      // ],
      del: [
        {
          name: '已到期',
          value: true
        }, {
          name: '未到期',
          value: false
        }
      ],
      tabIndex: [
        {
          name: '在校生',
          value: false
        }, {
          name: '离校生',
          value: true
        }
      ],
      // 服务类型选择项
      service: [
        {
          name: '午托',
          value: 'MORNING'
        }, {
          name: '下午托',
          value: 'AFTERNOON'
        }, {
          name: '作业托',
          value: 'WORK'
        }, {
          name: '其他',
          value: 'OTHER'
        }
      ],
      studyCurriculumIdsOptions: [],
      gendan: [
        {
          name: '男',
          value: true
        }, {
          name: '女',
          value: false
        }
      ],
      grade: [
        {
          name: '一年级',
          value: 'FIRST_GRADE'
        },
        {
          name: '二年级',
          value: 'SECOND_GRADE'
        },
        {
          name: '三年级',
          value: 'THIRD_GRADE'
        },
        {
          name: '四年级',
          value: 'FOURTH_GRADE'
        },
        {
          name: '五年级',
          value: 'FIFTH_GRADE'
        },
        {
          name: '六年级',
          value: 'SIXTH_GRADE'
        },
        {
          name: '七年级',
          value: 'SEVENTH_GRADE'
        },
        {
          name: '八年级',
          value: 'EIGHTH_GRADE'
        },
        {
          name: '九年级',
          value: 'NINTH_GRADE'
        },
        {
          name: '幼小',
          value: 'YOUNG_CONNECTION'
        },
        {
          name: '其他',
          value: 'OTHER'
        },
        {
          name: '未填年级',
          value: null
        }
      ],
      // 选择家长
      studentParentsDialog: false,
      // 家长集
      studentParentsTable: [],
      // 列表分页展示的数据
      studentParentsTableItem: [],
      search_data_parents: {
        mobile: '',
        parentName: ''
      },
      paginationsDialog: {
        current_page: 1,
        total: 0,
        page_size: 5,
        layout: 'prev, pager, next'
      },
      checkBoxParents: [],
      studentParents: {
        accountId: 0,
        mobile: '',
        relationship: '',
        parentName: '',
        avatarImg: '',
        standbyMobile: ''
      },
      studentParentsAdd: false,
      relationship: [
        {
          name: '爸爸',
          value: 'FATHER'
        },
        {
          name: '妈妈',
          value: 'MOTHER'
        },
        {
          name: '爷爷',
          value: 'GRANDFATHER'
        },
        {
          name: '奶奶',
          value: 'GRANDMOTHER'
        },
        {
          name: '外公',
          value: 'W_GRANDFATHER'
        },
        {
          name: '外婆',
          value: 'W_GRANDMOTHER'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      parents: {
        relationship: [
          { required: true, message: '请选择关系', trigger: 'change' }
        ],
        parentName: [
          { required: true, message: '请输入家长姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ]
      },
      studentRules: {
        'student.studentName': [
          { required: true, message: '请输入学生姓名', trigger: 'blur' }
        ],
        // 'student.birthday': [
        //   { required: true, message: '请选择生日日期', trigger: 'change' }
        // ],
        'student.grade': [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        servicesArr: [
          { type: 'array', required: true, message: '请至少选择一种服务', trigger: 'change' }
        ],
        expiryDate: [
          { required: true, message: '请选择服务到期时间', trigger: 'change' }
        ]
      },
      // 编辑状态
      submitType: '',
      parentsType: '',
      editIndex: '',
      expiryRange: false,
      studentCondition: false,
      serviceData: [],
      // 选择的学生
      checkStudents: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    handleSelectionChange (item) {
      this.checkStudents = item
    },
    fileBeforeUpload () {
      this.$store.state.global.ajax_loading = true
    },
    fileOnSuccess () {
      this.$store.state.global.ajax_loading = false
      this.$message({
        message: '上传成功！',
        type: 'success'
      })
      this.onSearch()
    },
    onExport () {
      this.$confirm('确定导出当前学生信息， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let filteData = this.expiryRange ? {
          studentCondition: 'EXPIRY',
          expiryRange: 'ALREADY_EXPIRY'
        } : {
          studentCondition: 'NORMAL',
          expiryRange: 'ALREADY_EXPIRY'
        }
        let listData  = this.expiryRange === null ? null : filteData
        listData = listData === null ?
          (this.studentCondition === null ? null : {isLeave: this.studentCondition}):
          (this.studentCondition === null ? null : Object.assign(filteData, {isLeave: this.studentCondition}))
        listData =  this.serviceData.length !== 0 ? (Object.assign(listData ? listData: {}, {service: this.serviceData.join()})) : listData
        listData = Object.assign(listData ? listData: {}, this.studyClassId ? {studyClassId: this.studyClassId} : {})

        var data = {
          data: listData,
          filters: null
        }
        var where = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value !== '') {
            where.push(this.search_data.filters[s])
          }
        }
        if (where) {
          data = Object.assign(data, { filters: where } || {})
        }

        ApiStudentManagement.exportZoneStudentsInfo.call(this, data, res => {
          // window.location.href = res.result
          const a = document.createElement('a')
          a.setAttribute('href', res.result)
          a.click()
        })
      })
    },
    fileOnError (err, file, fileList) {
      this.$store.state.global.ajax_loading = false
      this.$message({
        message: JSON.parse(err.message).message,
        type: 'error'
      })
    },
    getImg (img) {
      if (img) {
        return `<el-image :src="qiniu + scope.row.student.avatarImg"  style="border-radius: 10px;width: 80px;height: 80px"  fit="contain">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>`
      } else {
        return '<img src="../../../../../../static/images/default/default.jpg"  style="border-radius: 10px;width: 80px;height: 80px"  fit="contain" />'
      }
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'student.grade',
          operator: 'eq',
          value: ''
        }, {
          field: 'student.gender',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: ''
        }, {
          field: 'expiry',
          operator: 'eq',
          value: ''
        }, {
          field: 'studentCode',
          operator: 'like',
          value: ''
        }]
      }
      this.expiryRange = null
      this.studentCondition = null
      this.serviceData = []
      this.studyClassId = ''
      this.onSearch()
    },
    // 关闭学生新增编辑窗口
    closeParents (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.studentParents = {
            accountId: 0,
            mobile: '',
            relationship: '',
            parentName: '',
            avatarImg: '',
            standbyMobile: ''
          }
          this.imageUrl1 = ''
          this.imageUrl1Name = ''
          done()
        })
        .catch(_ => {})
    },
    // 关闭学生新增编辑窗口
    closeStudent (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.studentDetail = {
            checked: false,
            studentId: 0,
            expiryDate: '',
            studyCurriculumIds: [],
            enrollmentDate: '',
            student: {
              letter: '',
              studentName: '',
              birthday: '',
              address: '',
              avatarImg: '',
              gender: true,
              grade: '',
              school: null,
              schoolClass: ''
            },
            servicesArr: [],
            services: '',
            studentParents: [
              // {
              //   accountId: 0,
              //   mobile: '',
              //   relationship: '',
              //   parentName: '',
              //   avatarImg: '',
              //   standbyMobile: ''
              // }
            ],
            remark: ''
          }
          this.imageUrl = ''
          this.imageUrlName = ''
          done()
        })
        .catch(_ => {})
    },
    // 取消新增编辑学生窗口
    resetStudent () {
      this.studentDetail = {
        checked: false,
        studentId: 0,
        expiryDate: '',
        studyCurriculumIds: [],
        enrollmentDate: '',
        student: {
          letter: '',
          studentName: '',
          birthday: '',
          address: '',
          avatarImg: '',
          gender: true,
          grade: '',
          school: null,
          schoolClass: ''
        },
        servicesArr: [],
        services: '',
        studentParents: [
          // {
          //   accountId: 0,
          //   mobile: '',
          //   relationship: '',
          //   parentName: '',
          //   avatarImg: '',
          //   standbyMobile: ''
          // }
        ],
        remark: ''
      }
      this.DetailFormVisibleAdd = false
    },
    // 新增家长窗口
    getParentsAdd () {
      this.studentParents = {
        accountId: 0,
        mobile: '',
        relationship: '',
        parentName: '',
        avatarImg: '',
        standbyMobile: ''
      }
      this.parentsType = 'add'
      this.studentParentsAdd = true
    },
    onStudentDelete (id) {
      this.$confirm('确定删除该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiStudentManagement.onDeleteByZoneStudentId.call(this, {
          studentId: id
        }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getList()
        })
      })
    },
    getParentsDelete (index) {
      this.studentDetail.studentParents.splice(index, 1)
    },
    getParentsEdit (row, index, type) {
      this.editIndex = index
      this.studentParents = row
      this.parentsType = type
      this.studentParentsAdd = true
      this.imageUrl1 = (row.avatarImg && row.avatarImg.indexOf('http') > -1) ? row.avatarImg: this.qiniu + row.avatarImg
      this.imageUrl1Name = row.avatarImg
    },
    addstudentParents (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.studentParents.avatarImg = this.imageUrl1Name

          if (this.parentsType === 'add') {
            this.studentDetail.studentParents.push(this.studentParents)
          } else {
            this.studentDetail.studentParents[this.editIndex] = this.studentParents
          }
          this.studentParentsAdd = false
          this.studentParents = {
            accountId: 0,
            mobile: '',
            relationship: '',
            parentName: '',
            avatarImg: '',
            standbyMobile: ''
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getStudentParentsresetForm () {
      this.search_data_parents = {
        mobile: '',
        parentName: ''
      }
      this.getStudentParents()
    },
    selectChangeStudentParent (val) {
      this.checkBoxParents = val
    },
    // 选择家长接口
    getStudentParents () {
      ApiStudentManagement.getStudentParents.call(this, this.search_data_parents, data => {
        this.studentParentsTable = data
        this.studentParentsTableItem = this.studentParentsTable.slice((this.paginationsDialog.current_page - 1) * this.paginationsDialog.page_size, this.paginationsDialog.current_page * this.paginationsDialog.page_size)
        this.paginationsDialog.total = data.length
        this.studentParentsDialog = true
      })
    },
    saveStudentParents () {
      console.log(this.checkBoxParents)
      this.checkBoxParents.map(item => {
        const list = {
          avatarImg: item.avatarImg,
          accountId: item.accountId,
          mobile: item.mobile,
          relationship: item.relationship,
          parentName: item.parentName,
          standbyMobile: item.standbyMobile,
          createTime: null
        }
        console.log(list)
        this.studentDetail.studentParents.push(list)
      })
      this.studentParentsDialog = false
    },
    // 保存接口
    onSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.studentDetail.studentParents.length < 1) {
            this.$message.error('请添加家长信息！')
            return false
          } else {
            this.studentDetail.services = this.studentDetail.servicesArr.map(item => {
              if (item === '午托') {
                item = 'MORNING'
                return item
              } else if (item === '下午托') {
                item = 'AFTERNOON'
                return item
              } else if (item === '作业托') {
                item = 'WORK'
                return item
              } else if (item === '其他') {
                item = 'OTHER'
                return item
              }
            })
            // this.studentDetail.student.avatarImg = this.imageUrlName
            this.studentDetail.services = this.studentDetail.services.join()
            if (this.submitType === 'add') {
              ApiStudentManagement.onStudentAdd.call(this, this.studentDetail, res => {
                this.$refs[formName].resetFields()
                this.getList()
                this.studentDetail = {
                  checked: false,
                  studentId: 0,
                  expiryDate: '',
                  studyCurriculumIds: [],
                  enrollmentDate: '',
                  student: {
                    letter: '',
                    studentName: '',
                    birthday: '',
                    address: '',
                    avatarImg: '',
                    gender: true,
                    grade: '',
                    school: null,
                    schoolClass: ''
                  },
                  services: [],
                  servicesArr: [],
                  studentParents: [],
                  remark: ''
                }
                this.imageUrl = ''
                this.DetailFormVisibleAdd = false
              })
            } else {
              ApiStudentManagement.onStudentUpdate.call(this, this.studentDetail, res => {
                this.$refs[formName].resetFields()
                this.getList()
                this.studentDetail = {
                  checked: false,
                  studentId: 0,
                  expiryDate: '',
                  studyCurriculumIds: [],
                  enrollmentDate: '',
                  student: {
                    letter: '',
                    studentName: '',
                    birthday: '',
                    address: '',
                    avatarImg: '',
                    gender: true,
                    grade: '',
                    school: null,
                    schoolClass: ''
                  },
                  services: [],
                  servicesArr: [],
                  studentParents: [],
                  remark: ''
                }
                this.imageUrl = ''
                this.DetailFormVisibleAdd = false
              })
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleAvatarSuccess1 (res, file) {
      this.imageUrl1 = this.qiniu + res.result
      this.imageUrl1Name = res.result
    },
    beforeAvatarUpload1 (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = this.qiniu + res.result
      this.imageUrlName = res.result
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    addDetail (type) {
      this.edit = type
      this.submitType = type
      this.getStudyCurriculumIdsOptions()
      this.DetailFormVisibleAdd = true

      // this.$refs.studentForm.resetFields()
    },
    getStudyCurriculumIdsOptions () {
      ApiClassManagement.getClassList.call(this, { studyClassCondition: 'CLASS_MANAGEMENT', studyClassType: 'HOSTING' }, (listData) => {
        this.studyCurriculumIdsOptions = listData
      })
    },
    deleteCheckDetail () {
      if (this.checkStudents.length < 1) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的学生!'
        })
        return
      }
      this.$confirm('确定删除所有勾选的学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        this.checkStudents.map(item => {
          ids.push(item.studentId)
        })
        ApiStudentManagement.batchDeleteByZoneStudentId.call(this, ids, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getList()
        })
      })
    },
    deleteDetail (item) {
      this.$confirm('确定' + (item.del ? '激活' : '暂停') + '学生的所有服务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (item.del) {
          let studentParents = []
          studentParents = item.studentParents.map(list => {
            list.createTime = null
            return list
          })
          item.studentParents = studentParents
          ApiStudentManagement.onStudentActive.call(this, item, res => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getList()
          })
        } else {
          ApiStudentManagement.onStudentDelete.call(this, { studentId: item.studentId }, res => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getList()
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    editDetail (row, type) {
      this.edit = type
      this.submitType = type
      let arr = []
      this.getStudyCurriculumIdsOptions()
      if (row.studentParents) {
        arr = row.studentParents.map(item => {
          const list = {
            accountId: item.accountId,
            mobile: item.mobile,
            relationship: item.relationship,
            parentName: item.parentName,
            standbyMobile: item.standbyMobile,
            createTime: null,
            avatarImg: item.avatarImg
          }
          return list
        })
      }
      this.studentDetail = {
        checked: row.checked,
        studentId: row.studentId,
        expiryDate: row.expiryDate,
        studyCurriculumIds: row.studyCurriculumIds,
        enrollmentDate: row.enrollmentDate,
        student: {
          studentId: row.student.studentId,
          letter: row.student.letter,
          studentName: row.student.studentName,
          birthday: row.student.birthday,
          address: row.student.address,
          avatarImg: row.student.avatarImg,
          gender: row.student.gender,
          grade: row.student.grade,
          school: row.student.school,
          schoolClass: row.student.schoolClass
        },
        services: row.services,
        servicesArr: [],
        studentParents: arr,
        remark: row.remark
      }
      // this.studentDetail = Object.assign(this.studentDetail, row)
      this.imageUrl = this.qiniu + this.studentDetail.student.avatarImg
      this.studentDetail.services = this.studentDetail.services.split(',')
      this.studentDetail.servicesArr = this.studentDetail.services.map(item => {
        if (item === 'MORNING') {
          item = '午托'
          return item
        } else if (item === 'AFTERNOON') {
          item = '下午托'
          return item
        } else if (item === 'WORK') {
          item = '作业托'
          return item
        } else if (item === 'OTHER') {
          item = '其他'
          return item
        }
      })
      this.DetailFormVisibleAdd = true
    },
    getDetail (row) {
      this.DetailFormVisible = true
      this.dateDetail = row
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value !== '') {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变弹窗中当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPageDialog (page) {
      this.studentParentsTableItem = this.studentParentsTable.slice((page - 1) * this.paginationsDialog.page_size, page * this.paginationsDialog.page_size)
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getStudyClass () {
      let data = {
        studyClassCondition: 'CLASS_MANAGEMENT',
        studyClassType: 'HOSTING,TRAINING'
      }
      ApiStudentManagement.getStudyClass.call(this, {}, res => {
        this.studyClass = res.map(item => {
          return {
            studyClassId: item.studyClassId,
            studyClassName: item.studyClassName,
            // zoneTeacher: item.zoneTeacher
          }
        })
        this.studyClass = [...this.studyClass, {
          studyClassId: -1,
          studyClassName: '未入班',
        }]
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      let filteData = this.expiryRange ? {
        studentCondition: 'EXPIRY',
        expiryRange: 'ALREADY_EXPIRY'
      } : {
        studentCondition: 'NORMAL',
        expiryRange: 'ALREADY_EXPIRY'
      }
      let listData  = this.expiryRange === null ? null : filteData
      listData = listData === null ?
        (this.studentCondition === null ? null : {isLeave: this.studentCondition}):
        (this.studentCondition === null ? listData : Object.assign(filteData, {isLeave: this.studentCondition}))
      listData =  this.serviceData.length !== 0 ? (Object.assign(listData ? listData: {}, {service: this.serviceData.join()})) : listData
      listData = Object.assign(listData ? listData: {}, this.studyClassId !== '' ? {studyClassId: this.studyClassId} : {})
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: listData,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'studentId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiStudentManagement.getStudentList.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getStudyClass()
    this.getList()
  }
}
