import request from '@/config/request.js'
const ApiUser = request.User
const ApiRole = request.Role
export default {
  name: 'user-user',
  data () {
    return {
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      dailogRole: false,
      edit: 'add',
      userRoleDetail: {
        roleId: '',
        userId: '',
        id: ''
      },
      formLabelWidth: '120px',
      userRoleRules: {
        roleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ]
      },
      role: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {

    onCancelRole (item) {
      this.$confirm('是否确定取消角色绑定？是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(item)
        ApiUser.bindUserRole.call(this, {
        roleId: '',
        userId: item.userRole.userId,
        id: item.userRole.id
      }, res => {
          this.getList()
        })
      }).catch(() => {
      })
    },
    getRoleDetail (row, type) {
      const that = this
      that.edit = type
      ApiRole.getRoles.call(this, {}, (listData) => {
        this.role = listData
        this.dailogRole = true
        that.$nextTick(() => {
          if (type === 'add') {
            delete that.userRoleDetail.id
          } else {
            that.userRoleDetail.id = row.userRole.id
            that.userRoleDetail.roleId = row.systemRole.roleId
          }
          that.userRoleDetail.userId = row.teacherId
        })
      })
    },
    onSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.bindUserRole.call(this, this.userRoleDetail, res => {
            this.getList()
            this.$refs[formName].resetFields()
            this.dailogRole = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetRole (formName) {
      this.$refs[formName].resetFields()
      this.$nextTick(() => {
        this.dailogRole = false
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      ApiUser.getUsers.call(this, {}, (listData) => {
        this.tableList = listData
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
